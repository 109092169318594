<template>
  <f7-page name="profsalud" :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar class="navbarprofsalud" :sliding="false" >
     
      <f7-nav-title sliding title="coronachile.com" :subtitle="profile_userInfo.email"></f7-nav-title>
       <f7-nav-right>
        <f7-link  icon-material="exit_to_app" @click="prelogout()"></f7-link>
      </f7-nav-right>
    </f7-navbar>

  <f7-toolbar tabbar labels top>
      <f7-link 
        tab-link="#redemecoder" 
        tab-link-active  
        text="Nuevo" 
        icon-material="playlist_add">
      </f7-link>

      <f7-link 
        tab-link="#pendientes" 
        text="Formularios" 
        icon-material="list">
      </f7-link>
   
       <f7-link tab-link="#profile">
      <f7-icon material="account_circle">
        <f7-badge v-if="(Object.keys(myInstitx).length==0)" color="red">1</f7-badge>
      </f7-icon>
      <span class="tabbar-label">Perfil</span>
    </f7-link>
    </f7-toolbar>
  <f7-tabs swipeable>
    <f7-tab id="redemecoder" class="page-content grisback" tab-active>
      <template v-if="(Object.keys(myInstitx).length>0)">
      <f7-block class="bloqueredeem">
              <f7-block-title class="no-margin-top">Ingrese código de formulario</f7-block-title>

        <f7-row>
        <f7-col width="20">
          <f7-input type="text"
          placeholder="X"
           :value="prefix"
          @input="prefix = $event.target.value"
          ></f7-input>
    </f7-col>
        <f7-col width="40">
           <f7-input type="number"
          placeholder="0000"
           :value="code"
          @input="code = $event.target.value"
          ></f7-input>
        </f7-col>
        <f7-col width="40">
          <f7-input type="number"
          placeholder="0000"
           :value="sufix"
        @input="sufix = $event.target.value"
          ></f7-input>
        </f7-col>
      </f7-row>
      </f7-block>
      <f7-block class="margin-top"> <f7-button @click="validate()"  fill color="purple" raised>Validar Código</f7-button></f7-block>
      </template>
      <f7-block v-else >Para poder usar coronachile.com, debe indicar a qué institución pertenece, en el menu Perfil, arriba a la derecha.</f7-block>
      <f7-block>CoronaChile.com NO es un reemplazo de EPIVIGILA. Es una herramienta para facilitar la tarea de recolectar información del paciente para la generación de los formularios obligatorios requeridos por el ISP/Minsal, que Ud deberá imprimir y enviar por los canales oficiales existentes. Delegando al paciente la carga de sus datos, ahorramos tiempo crítico de los clínicos, agilizando la labor de vigilancia epimediológica obligatoria.</f7-block>
      <f7-block >Información IMPORTANTE: Sección de síntomas no se pregunta en formulario de paciente, debe llenarlo en su formulario clínico.</f7-block>

    </f7-tab>
    <f7-tab id="pendientes" class="page-content grisback" >
  <pendiente v-for="unaformid in myformIds" :key="unaformid" :fuid="unaformid"></pendiente>
    </f7-tab>
    <f7-tab id="profile" class="page-content grisback">
      <f7-list class="no-margin-top profmetadata" >
         <f7-list-item divider title="Datos Personales"></f7-list-item>
        <f7-list-item header="email" :title="profile_userInfo.email">
          <f7-icon material="verified_user" slot="after" class="text-color-teal" v-if="profile_userInfo.emailVerified"></f7-icon>
        </f7-list-item>
        <f7-list-item header="Nombre" :title="myName" link="#" @click="editarNombre()">
          <f7-icon material="edit" slot="after"></f7-icon>
        </f7-list-item>
         <f7-list-item header="RUT" :title="misprivx.rut || '-'"  :class="{nodef:!misprivx.rut}" link="#" @click="editarInfoPersonal('rut')">
          <f7-icon material="edit" slot="after"></f7-icon>
        </f7-list-item>

      </f7-list>

  <f7-block v-if="(Object.keys(myInstitx).length==0)">Debe crear una institución para poder prellenar esos campos en los formularios.</f7-block>

<f7-card v-for="institu in misprivx.inst" :key="institu.id" >
  <f7-card-content>
    <f7-list>
      <f7-list-item divider class="headviints" :title="institu.institucion || 'Pendente indicar nombre'"></f7-list-item>
      <f7-list-item title="Unidad" :after="institu.unidad ||'-'"></f7-list-item>
      <f7-list-item title="Fono" :after="institu.fono ||'-'"></f7-list-item>
      <f7-list-item title="Fax" :after="institu.fax ||'-'"></f7-list-item>
      <f7-list-item title="Direccion" :after="institu.direccion ||'-'"></f7-list-item>
      <f7-list-item title="Comuna" :after="institu.comuna ||'-'"></f7-list-item>
      <f7-list-item title="Provincia" :after="institu.provincia ||'-'"></f7-list-item>
      <f7-list-item title="Región" :after="institu.region ||'-'"></f7-list-item>
    </f7-list>
  </f7-card-content>
  <f7-card-footer>
    <span></span>
    <f7-button @click="irainstitucion(institu)" outline>Editar</f7-button>
  </f7-card-footer>
</f7-card>
<f7-block v-if="(Object.keys(myInstitx).length>=1)">Si Ud. trabaja en más de una institución, puede crear más de una, y elegir cuál usar al momento del llenado de formularios.</f7-block>
<f7-block><f7-button @click="irainstitucion(null)" outline>Crear Nueva Institución</f7-button></f7-block>
    </f7-tab>
  </f7-tabs>
   

  </f7-page>
</template>
<script>
import {mapState, mapGetters} from 'vuex';
import { validate, format, clean} from 'rut.js';
import pendiente from '../components/pendiente.vue';

export default {
    components: {
        pendiente
    },
   data(){
     return{
       prefix: '',
       code: '',
       sufix: ''
     }
   },
    computed:{
    ...mapState(['defaultAvatar','profile_userInfo','misprivx']),
     ...mapGetters(['myAvatar','myName','myformIds','myInstitx']),
   
  },
  mounted(){
    console.log(this.misprivx,'misprivx');
  },
  methods:{
    irainstitucion(institucionObj){
      if(!institucionObj){
        this.$f7.dialog.preloader('Creando nueva institución...');
        //crear institucion primero
        let iuid = this.$firebase.database().ref('privx/'+this.profile_userInfo.uid+'/inst').push().key;
        let nuevainstitucion={id: iuid};
        this.$firebase.database().ref('privx/'+this.profile_userInfo.uid+'/inst/'+iuid).set(nuevainstitucion).then(()=>{
          //addInstitucionToPrivx
           this.$store.commit('addInstitucionToPrivx',{what:'uid',to:nuevainstitucion});
               this.$store.commit('setWhatTo',{what:'focusedInstitucion',to:nuevainstitucion});
               this.$f7.dialog.close();
              this.$f7.views.main.router.navigate('/formainstitution/');
        }).catch(error=>{
             this.$f7.dialog.close();
             this.$f7.dialog.alert(error.message,error.code);
        });
      }
      else{
         this.$store.commit('setWhatTo',{what:'focusedInstitucion',to:institucionObj});
         this.$f7.views.main.router.navigate('/formainstitution/');
      }
    },
    validate(){
      if(!this.prefix || !this.code || !this.sufix ){
        return this.$f7.dialog.alert('Debe ingresar código completo', 'Faltan dígitos');
      }
      let codeToredeem=this.prefix.toUpperCase()+'-'+this.code+'-'+this.sufix;
       this.$f7.dialog.preloader('Validando código...');
      console.log('validando codigo',codeToredeem);
      let redeem = this.$firebase.functions().httpsCallable('redeem');
      redeem({code:codeToredeem}).then((ladata)=> {
        console.log('llego data',ladata);
        let elform=ladata.data;
        //myforms
        this.$store.commit('addFormToPrivx',elform.eluid);
        this.prefix='';
        this.code='';
        this.sufix='';
        this.$f7.dialog.close();
         this.$f7.dialog.alert('Código validado ok. Vaya a TAB de formularios para ver su cola de trabajo.','Éxito');
      }).catch((error)=>{
        console.log('error en fx',error);
        this.$f7.dialog.close();
        this.$f7.dialog.alert(error.message || error,'Error');
      });
    },
 
     editarNombre(){
this.$f7.dialog.prompt('Indique su nombre',  (nombre)=> {
  let fixedNombre=nombre.trim();
  if(fixedNombre==""){
    return this.$f7.dialog.alert('Debes indicar un nombre');
  }
  this.$f7.dialog.preloader('Guardando');
    this.$firebase.auth().currentUser.updateProfile({
      displayName: nombre
    }).then(()=> {
      // Update successful.
      this.profile_userInfo.displayName=nombre;
      this.$f7.dialog.close();
    }).catch((error)=> {
      // An error happened.
       this.$f7.dialog.close();
      this.$f7.dialog.alert(error.message);
    });
  },
()=>{}
  ,
    (this.profile_userInfo.displayName!='Indique su nombre'?this.profile_userInfo.displayName:null)

  );
  },
  editarInfoPersonal(cualprop){
    this.$f7.dialog.prompt('Indique '+cualprop,  (nombre)=> {
      let fixedNombre=nombre.trim();
       if(fixedNombre==""){
        return this.$f7.dialog.alert('Debes indicar '+cualprop);
      }
      if(cualprop=='rut'){
       // let fixedNombre=format(fixedNombre);
        if(!validate(fixedNombre)){
           return this.$f7.dialog.alert('RUT no es válido');
        }
        fixedNombre=format(fixedNombre);
      }
      this.$f7.dialog.preloader('Guardando');
        this.$firebase.database().ref('privx/'+this.profile_userInfo.uid+'/'+cualprop).set(fixedNombre).then(()=> {
          // Update successful.
           this.$store.commit('setOrigenPropTo',{what:cualprop,to:fixedNombre});
          this.$f7.dialog.close();
        }).catch((error)=> {
          // An error happened.
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message);
        });
      },
    ()=>{}
      ,
        (this.misprivx[cualprop]||null)

      );
  },

     prelogout(){
       this.$f7.dialog.confirm('Confirme que desea cerrar sesión','Cerrar sesión', ()=> {
          this.logout();
        });
    },
       logout() {
        console.log('Logging out');
        this.$f7.dialog.preloader('Cerrando sesión...');
        this.$firebase.auth().signOut().then(()=> {
          console.log('Sign-out successful.');
          this.$store.commit('setObjTo',{what:'misprivx',to:{}});
           this.$store.commit('setObjTo',{what:'profile_userInfo',to:{}});
          this.$f7.dialog.close();
        }).catch((error)=> {
          this.$f7.dialog.close();
          console.log('Sign-out error.', error);
        });
      }
  }
}
</script>
<style>
.navbar .subtitle{
  color:#9c27b0!important;
  font-weight: bold;
}
.tab-link-active .tabbar-label{
font-weight: bold!important;
}

.navbarprofsalud .navbar-bg {
background: url('../static/img/coronaback.jpg')!important;
background-repeat:no-repeat;
-webkit-background-size:cover;
-moz-background-size:cover;
-o-background-size:cover;
background-size:cover;
background-position:center;
}

.profmetadata .item-header{
  font-weight: bold;
    text-transform: uppercase;
    color: #0179ff;
}
.nodef{
  color: #ff5c5c;
}
.nodef .smart-select .item-after{
  color:#FFF!important;
}
.bloqueredeem{
  background: #cbe4ff;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.bloqueredeem .input{
  background: #fff;
    text-align: center;
    padding: 7px 4px;
    line-height: 20px;
    font-size: 20px;
    border-radius: 12px;
}

.bloqueredeem .input input {
   text-align: center!important;
}

.bloqueredeem .input input, .bloqueredeem .input input::-webkit-input-placeholder, .bloqueredeem .input input:-moz-placeholder , .bloqueredeem .input input::-moz-placeholder , .bloqueredeem .input input:-ms-input-placeholder {
   text-align: center!important;
}
.grisback{
  background: #f1f1f1;
}

.headviints.item-divider{
  font-weight: bold;
    background: #e7f2ff;
}
</style>