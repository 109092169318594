<template>
  <f7-page class="grisback soyformaone" name="formaone">
    <f7-navbar :title="formaName" back-link="Back">
      <f7-nav-right>
        <f7-link icon-material="email" @click="requestMailSend()"></f7-link>
        <f7-link icon-material="print" @click="printme()"></f7-link>
        </f7-nav-right>
    </f7-navbar>
    <f7-block>
    <f7-block-title>1.-DATOS GENERALES DEL PACIENTE</f7-block-title>
          <p>Datos ingresados por el paciente. Ud puede modificarlos si lo considera necesario.</p>
  <f7-row>
    <f7-col width="100" medium="50">
      <f7-list class="preprint no-margin-top no-margin-bottom">
         <f7-list-input
        label="RUT"
        type="text"
        placeholder="RUT o Pasaporte"
        :value="focusedForm.rutid"
        @input="focusedForm.rutid = $event.target.value"
        @blur="grabarEsto('rutid')"
      ></f7-list-input>
      <f7-list-input
        label="Nombres"
        type="text"
        placeholder="Nombres"
        :value="focusedForm.nombres"
        @input="focusedForm.nombres = $event.target.value"
        @blur="grabarEsto('nombres')"
      ></f7-list-input>
      <f7-list-input
        label="Apellido Paterno"
        type="text"
        placeholder="Apellido Paterno"
        :value="focusedForm.apepat"
        @input="focusedForm.apepat = $event.target.value"
        @blur="grabarEsto('apepat')"
      ></f7-list-input>
      <f7-list-input
        label="Apellido Materno"
        type="text"
        placeholder="Apellido Materno"
        :value="focusedForm.apemat"
        @input="focusedForm.apemat = $event.target.value"
        @blur="grabarEsto('apemat')"
      ></f7-list-input>
       <f7-list-input
          label="Sexo"
          type="select"
          :value="focusedForm.sexo"
          @input="focusedForm.sexo = $event.target.value"
          @change="grabarEsto('sexo')"
          placeholder="Seleccione..."
        >
        <option value="">Seleccione</option>
          <option value="Masculino">Masculino</option>
          <option value="Femenino">Femenino</option>
        </f7-list-input>
        <li><fechcomp name="Fecha de Nacimiento" propiedad="fechanac"></fechcomp></li>
        <f7-list-input
        label="Edad"
        class="clpreadonly"
        type="text"
        placeholder="Edad"
        :value="suageparts(focusedForm.fechanac)"
       readonly
      ></f7-list-input>

      <f7-list-input
        label="Servicio de Salud al que corresponde"
        type="text"
        placeholder="Servicio de salud"
         :value="focusedForm.serviciosalud"
        @input="focusedForm.serviciosalud = $event.target.value"
        @blur="grabarEsto('serviciosalud')"
      ></f7-list-input>
      </f7-list>
    </f7-col>
    <f7-col width="100" medium="50">
 <f7-list class="preprint no-margin-top no-margin-bottom">
         <f7-list-input
        label="Domicilio"
        type="text"
        placeholder="Domicilio"
        :value="focusedForm.domicilio"
        @input="focusedForm.domicilio = $event.target.value"
        @blur="grabarEsto('domicilio')"
      ></f7-list-input>
      <f7-list-input
        label="Región"
        type="text"
        placeholder="Región"
        :value="focusedForm.region"
       readonly
      ></f7-list-input>
      <f7-list-input
        label="Ciudad"
        type="text"
        placeholder="Ciudad"
        :value="focusedForm.provincia"
        readonly
      ></f7-list-input>
      <f7-list-input
        label="Comuna"
        type="text"
        placeholder="Comuna"
        :value="focusedForm.comuna"
       readonly
      ></f7-list-input>
          <f7-list-input
        label="Ruralidad"
        type="select"
        :value="focusedForm.ruralidad"
        @input="focusedForm.ruralidad = $event.target.value"
        defaultValue="Zona Urbana"
        placeholder="Seleccione..."
        @change="grabarEsto('ruralidad')"
      >
        <option value="Zona Urbana">Zona Urbana</option>
        <option value="Zona Rural">Zona Rural</option>
      </f7-list-input>
      <f7-list-input
        label="Telefono"
        type="text"
        placeholder="Telefono"
        :value="focusedForm.fono"
        readonly
      ></f7-list-input>
       <f7-list-input
        label="Previsión"
        type="text"
        placeholder="Previsión"
        :value="focusedForm.prevision"
        readonly
      ></f7-list-input>
       <f7-list-input
          label="Dirección Trabajo/Escuela"
          type="text"
          placeholder="Dirección Trabajo/Escuela"
          :value="focusedForm.direccion"
          @input="focusedForm.direccion = $event.target.value"
          @blur="grabarEsto('direccion')"
          clear-button
        >
        </f7-list-input>

        <f7-list-input
          label="Ocupación"
          type="text"
          placeholder="Ocupación"
          :value="focusedForm.ocupacion"
          @input="focusedForm.ocupacion = $event.target.value"
          @blur="grabarEsto('ocupacion')"
          clear-button
        >
        </f7-list-input>
 </f7-list>
    </f7-col>
  </f7-row>

    </f7-block>

    <f7-block-title>2.-ANTECEDENTES DE RIESGO Y EXPOSICIÓN</f7-block-title>
 <f7-block>
      <f7-row>
    <f7-col width="100" medium="50">
      <f7-list class="no-margin">
        <f7-list-item>
    <span>Viajó extranjero hace menos de 14 dias</span>
    <f7-toggle
            @change="toglethis($event,'viajex')"
            :checked="focusedForm.viajex"
            class="toggle"
        ></f7-toggle>
        </f7-list-item>  
        <f7-list-item v-for="(ciudadpais,idx) in focusedForm.ciudadesPaises" :key="idx" :footer="ciudadpais.fechas.join(' al ')" :title="ciudadpais.pais" :after="ciudadpais.ciudad" ></f7-list-item>

      </f7-list>
    </f7-col>
     <f7-col width="100" medium="50">
       <f7-list v-if="focusedForm.viajex" class="no-margin">
                 <li><fechcomp name="Fecha Retorno al Pais" propiedad="finviaje" ></fechcomp></li>
       </f7-list>
     </f7-col>
      </f7-row>
 </f7-block>

 <f7-block>
      <f7-row>
    <f7-col >
      <f7-list class="no-margin">
         <f7-list-item divider title="Contactos"></f7-list-item>
     <f7-list-item>
    <span>Tuvo contacto con personas enfermas o que hayan fallecido de enfermedades respiratorias</span>
    <f7-toggle 
    
            @change="toglethis($event,'contactoenfermas')"
            :checked="focusedForm.contactoenfermas"
            class="toggle"
        ></f7-toggle>
        </f7-list-item>  

  <f7-list-input
  v-show="focusedForm.contactoenfermas"
    label="Lugar donde ocurrió el contacto"
    type="text"
    placeholder="Lugar donde ocurrió el contacto"
    :value="focusedForm.lugar_contactoenfermas"
    @input="focusedForm.lugar_contactoenfermas = $event.target.value"
     @blur="grabarEsto('lugar_contactoenfermas')"
    clear-button
  >
  </f7-list-input>


       <f7-list-item>
    <span>Ha visitado mercados animales</span>
    <f7-toggle 
    
            @change="toglethis($event,'mercadosanimales')"
            :checked="focusedForm.mercadosanimales"
            class="toggle"
        ></f7-toggle>
        </f7-list-item>  

  <f7-list-input
  v-show="focusedForm.mercadosanimales"
    label="Lugar mercado animal"
    type="text"
    placeholder="Lugar mercado animal"
    :value="focusedForm.lugar_mercadoanimal"
    @input="focusedForm.lugar_mercadoanimal = $event.target.value"
     @blur="grabarEsto('lugar_mercadoanimal')"
    clear-button
  >
  </f7-list-input>

 <f7-list-item>
    <span>Ha estado en contacto con animales silvestres enfermos (vivos o muertos)?</span>
    <f7-toggle 
    
            @change="toglethis($event,'contactoanimalesenfermos')"
            :checked="focusedForm.contactoanimalesenfermos"
            class="toggle"
        ></f7-toggle>
        </f7-list-item>  

<f7-list-item>
    <span>Es trabajador de salud</span>
    <f7-toggle 
    
            @change="toglethis($event,'estrabajadorsalud')"
            :checked="focusedForm.estrabajadorsalud"
            class="toggle"
        ></f7-toggle>
        </f7-list-item>  

  <f7-list-input
  v-show="focusedForm.estrabajadorsalud"
    label="Establecimiento"
    type="text"
    placeholder="Establecimiento"
    :value="focusedForm.lugarTrabajadorSalud"
    @input="focusedForm.lugarTrabajadorSalud = $event.target.value"
      @blur="grabarEsto('lugarTrabajadorSalud')"
    clear-button
  >
  </f7-list-input>
       </f7-list>
     </f7-col>
      </f7-row>
 </f7-block>
  <f7-block-title>3.- ANTECEDENTES CLÍNICOS DE INICIO</f7-block-title>
   <f7-block>
  <f7-row>
    <f7-col width="100" medium="50">
      <f7-list class="preprint no-margin-top no-margin-bottom fgrislist">
        <li><fechcomp name="Fecha de Inicio de Síntomas" propiedad="fechainisint"></fechcomp></li>
      </f7-list>
    </f7-col>
    <f7-col width="100" medium="50">
      <f7-list class="preprint no-margin-top no-margin-bottom fgrislist">
        <li><fechcomp name="Fecha Primera Consulta" propiedad="fechaprimconsult"></fechcomp></li>
         <f7-list-input
    label="Centro primera consulta"
    type="text"
    placeholder="Centro primera consulta"
    :value="focusedForm.lugarPimeraConsulta"
    @input="focusedForm.lugarPimeraConsulta = $event.target.value"
     @blur="grabarEsto('lugarPimeraConsulta')"
    clear-button
  >
  </f7-list-input>
      </f7-list>
    </f7-col>
  </f7-row>
  <f7-row>
    <f7-col width="100">
  <f7-list class="no-margin alrededor">

  <f7-list-item divider title="Signos y sintomas"></f7-list-item>

   <f7-list-item
    v-for="untestpacl in sintomasf2" :key="untestpacl.id"
     checkbox 
     :title="untestpacl.nam" 
     :value="untestpacl.id" 
     :name="untestpacl.nam" 
     :checked=" (focusedForm.sintomasf2 && focusedForm.sintomasf2.includes(untestpacl.id))"
      @change="checkonebox($event.target,'sintomasf2')"></f7-list-item>


      <f7-list-input
    label="Otros"
    type="textarea"
    placeholder="Otros"
     :value="focusedForm.sintomasf2_otros"
    @input="focusedForm.sintomasf2_otros = $event.target.value"
     @blur="grabarEsto('sintomasf2_otros')"
  >
  </f7-list-input>
      </f7-list>

    </f7-col>
  </f7-row>



  <f7-row>
    <f7-col width="100">
  <f7-list class="no-margin alrededor">
  <f7-list-item divider title="Antecedente de uso de medicamentos para este evento"></f7-list-item>
 <f7-list-item>
    <span>Antipiréticos</span>
    <f7-toggle
      @change="toglethis($event,'antipireticos_uso')"
      :checked="focusedForm.antipireticos_uso"
      class="toggle"
    ></f7-toggle>
        </f7-list-item>
    <li v-show="focusedForm.antipireticos_uso" ><fechcomp name="Fecha Inicio Antipiréticos" propiedad="antipireticos_fechainiciotto"></fechcomp></li>

 <f7-list-item>
    <span>Antibióticos</span>
    <f7-toggle
      @change="toglethis($event,'antibioticos_uso')"
      :checked="focusedForm.antibioticos_uso"
      class="toggle"
    ></f7-toggle>
        </f7-list-item>
    <li v-show="focusedForm.antibioticos_uso" ><fechcomp name="Fecha Inicio Antibióticos" propiedad="antibioticos_fechainiciotto"></fechcomp></li>

  <f7-list-item>
    <span>Uso Antiviral</span>
    <f7-toggle
      @change="toglethis($event,'antiviral_uso')"
      :checked="focusedForm.antiviral_uso"
      class="toggle"
    ></f7-toggle>
        </f7-list-item>
     <li v-show="focusedForm.antiviral_uso"><fechcomp name="Fecha Inicio Antiviral" propiedad="antiviral_fechainiciotto"></fechcomp></li>
      </f7-list>
    </f7-col>
  </f7-row>



  <f7-row>
    <f7-col width="100">
  <f7-list class="no-margin alrededor">
  <f7-list-item divider title="Hospitalización"></f7-list-item>
<f7-list-item>
    <span>Hospitalizado</span>
    <f7-toggle
      @change="toglethis($event,'hospitliazdo')"
      :checked="focusedForm.hospitliazdo"
      class="toggle"
    ></f7-toggle>
        </f7-list-item>
 
  <template v-if="focusedForm.hospitliazdo" >
    <li ><fechcomp name="Fecha Hospitalización" propiedad="fecha_hospitalizacion"></fechcomp></li>
 <f7-list-input
    label="Diagnóstico de ingreso"
    type="text"
    placeholder="Diagnóstico de ingreso"
       :value="focusedForm.diagnoingrhosp"
        @input="focusedForm.diagnoingrhosp = $event.target.value"
        @blur="grabarEsto('diagnoingrhosp')"
    clear-button
  >
  </f7-list-input>
    
    </template>
     <f7-list-item divider title="Condiciones al ingreso"></f7-list-item>
 <f7-list-input
    label="FC"
    type="number"
    placeholder="FC"
    :value="focusedForm.condicIngre_fc"
    @input="focusedForm.condicIngre_fc = $event.target.value"
     @blur="grabarEsto('condicIngre_fc')"
    clear-button
  >
  </f7-list-input>
  <f7-list-input
    label="FR"
    type="number"
    placeholder="FR"
    :value="focusedForm.condicIngre_fr"
    @input="focusedForm.condicIngre_fr = $event.target.value"
     @blur="grabarEsto('condicIngre_fr')"
    clear-button
  >
  </f7-list-input>
  <f7-list-input
    label="Tensión Arterial Máx"
    type="text"
    placeholder="Tensión Arterial Máx"
    :value="focusedForm.condicIngre_tamax"
    @input="focusedForm.condicIngre_tamax = $event.target.value"
     @blur="grabarEsto('condicIngre_tamax')"
    clear-button
  >
  </f7-list-input>
  <f7-list-input
    label="Tensión Arterial Min"
    type="text"
    placeholder="Tensión Arterial Min"
    :value="focusedForm.condicIngre_tamin"
    @input="focusedForm.condicIngre_tamin = $event.target.value"
     @blur="grabarEsto('condicIngre_tamin')"
    clear-button
  >
  </f7-list-input>
  <f7-list-input
    label="Temperatura"
    type="text"
    placeholder="Temperatura"
    :value="focusedForm.condicIngre_temp"
    @input="focusedForm.condicIngre_temp = $event.target.value"
     @blur="grabarEsto('condicIngre_temp')"
    clear-button
  >
  </f7-list-input>
 </f7-list>
    </f7-col>
  </f7-row>




  <f7-row>
    <f7-col width="100">
  <f7-list class="no-margin alrededor">
  <f7-list-item divider title="Comorbilidades"></f7-list-item>
    <f7-list-item
    v-for="inmuno in comorbilidades" :key="inmuno.id"
     checkbox 
     :title="inmuno.nam" 
     :value="inmuno.id" 
     :name="inmuno.nam" 
     :checked=" (focusedForm.comorbilidades && focusedForm.comorbilidades.includes(inmuno.id))"
      @change="checkonebox($event.target,'comorbilidades')"></f7-list-item>
    <f7-list-item divider title="Factores de Riesgo"></f7-list-item>
    <f7-list-item
    v-for="inmuno in riskfactors" :key="inmuno.id"
     checkbox 
     :title="inmuno.nam" 
     :value="inmuno.id" 
     :name="inmuno.nam" 
     :checked=" (focusedForm.riskfactors && focusedForm.riskfactors.includes(inmuno.id))"
      @change="checkonebox($event.target,'riskfactors')"></f7-list-item>
  </f7-list>
    </f7-col>
  </f7-row>


    </f7-block>



    <f7-block-title>4.-DATOS DE LABORATORIO ETIOLOGICO</f7-block-title>
 <f7-block>
      <f7-list class="no-margin">
        <f7-list-item>
        <span>Toma de muestra IRA</span>
        <f7-toggle
                @change="toglethis($event,'muestra_ira')"
                :checked="focusedForm.muestra_ira"
                class="toggle"
        ></f7-toggle>
        </f7-list-item>
      </f7-list>
      <template v-if="focusedForm.muestra_ira">
      <f7-row v-for="tipomuestra in ['ANF','HNF','Sangre']" :key="'ds'+tipomuestra" >
        <f7-col width="100" medium="50">
      <f7-list class="no-margin">
             <f7-list-item >
            <span>{{tipomuestra}}</span>
            <f7-toggle
              @change="toglethis($event,'muestra_ira_'+tipomuestra)"
              :checked="focusedForm['muestra_ira_'+tipomuestra]"
              class="toggle"
            ></f7-toggle>
            </f7-list-item>
      </f7-list>
        </f7-col>
         <f7-col width="100" medium="50">
          <f7-list v-if="focusedForm['muestra_ira_'+tipomuestra]" class="no-margin">
            <li><fechcomp :name="'Fecha Toma '+tipomuestra" :propiedad="'fechtom_mus_ira_'+tipomuestra"></fechcomp></li>
      </f7-list>
        </f7-col>
      </f7-row>
      <f7-row >
        <f7-col width="100" medium="50">
      <f7-list class="no-margin">
             <f7-list-input
          label="Otro: Cual?"
          type="text"
          placeholder="Otro: Cual?"
          :value="focusedForm.muestra_ira_otro"
          @input="focusedForm.muestra_ira_otro = $event.target.value"
          @blur="grabarEsto('muestra_ira_otro')"
          clear-button
        >
        </f7-list-input>
      </f7-list>
        </f7-col>
         <f7-col width="100" medium="50">
          <f7-list class="no-margin">
            <li ><fechcomp name="Fecha Toma Otro" propiedad="fechtom_mus_ira_otro"></fechcomp></li>
      </f7-list>
        </f7-col>
      </f7-row>
      <f7-list>
        <f7-list-item divider title="Resultados"></f7-list-item>
         <f7-list-input
         v-for="unresult in ['IFI','PCR','Hemocultivo','OtroCultivo']" :key="unresult"
          :label="unresult"
          type="text"
          :placeholder="unresult"
          :value="focusedForm['muestra_ira_res_'+unresult]"
          @input="focusedForm['muestra_ira_res_'+unresult] = $event.target.value"
          @blur="grabarEsto('muestra_ira_res_'+unresult)"
          clear-button
        >
        </f7-list-input>
      </f7-list>
      </template>
   
 </f7-block>
  

 <f7-block>
      <f7-list class="no-margin">
        <f7-list-item>
        <span>Toma de muestra IRAG</span>
        <f7-toggle
                @change="toglethis($event,'muestra_irag')"
                :checked="focusedForm.muestra_irag"
                class="toggle"
        ></f7-toggle>
        </f7-list-item>
      </f7-list>
      <template v-if="focusedForm.muestra_irag">
      <f7-row v-for="tipomuestra in ['LavadoBroncoAlveolar','Esputo','AspiradoTraqueal','Sangre']" :key="'gds'+tipomuestra" >
        <f7-col width="100" medium="50">
      <f7-list class="no-margin">
             <f7-list-item >
            <span>{{tipomuestra}}</span>
            <f7-toggle
              @change="toglethis($event,'muestra_irag_'+tipomuestra)"
              :checked="focusedForm['muestra_irag_'+tipomuestra]"
              class="toggle"
            ></f7-toggle>
            </f7-list-item>
      </f7-list>
        </f7-col>
         <f7-col width="100" medium="50">
          <f7-list v-if="focusedForm['muestra_irag_'+tipomuestra]" class="no-margin">
            <li><fechcomp :name="'Fecha Toma '+tipomuestra" :propiedad="'fechtom_mus_irag_'+tipomuestra"></fechcomp></li>
      </f7-list>
        </f7-col>
      </f7-row>
      <f7-row >
        <f7-col width="100" medium="50">
      <f7-list class="no-margin">
             <f7-list-input
          label="Otro: Cual?"
          type="text"
          placeholder="Otro: Cual?"
          :value="focusedForm.muestra_irag_otro"
          @input="focusedForm.muestra_irag_otro = $event.target.value"
          @blur="grabarEsto('muestra_irag_otro')"
          clear-button
        >
        </f7-list-input>
      </f7-list>
        </f7-col>
         <f7-col width="100" medium="50">
          <f7-list class="no-margin">
            <li ><fechcomp name="Fecha Toma Otro" propiedad="fechtom_mus_irag_otro"></fechcomp></li>
      </f7-list>
        </f7-col>
      </f7-row>
      <f7-list>
        <f7-list-item divider title="Resultados"></f7-list-item>
         <f7-list-input
         v-for="unresult in ['IFI','PCR','Hemocultivo','OtroCultivo']" :key="unresult"
          :label="unresult"
          type="text"
          :placeholder="unresult"
          :value="focusedForm['muestra_irag_res_'+unresult]"
          @input="focusedForm['muestra_irag_res_'+unresult] = $event.target.value"
          @blur="grabarEsto('muestra_irag_res_'+unresult)"
          clear-button
        >
        </f7-list-input>
      </f7-list>
      </template>
   
 </f7-block> 


 <f7-block-title>5.-DATOS DEL NOTIFICANTE</f7-block-title>
    <f7-block>
      <f7-row>
    <f7-col width="100" medium="50">
      <f7-list class="no-margin">
        <li ><fechcomp name="Fecha de Notificación" propiedad="fecha_notificacion"></fechcomp></li>
        <f7-list-input
    label="Seleccione institución para cargar datos"
    type="select"
     :value="prefferedInsti"
    @input="focusedForm.prfi_dos = $event.target.value"
    @change="grabarEsto('prfi_dos')"
  >
    <option v-for="unainsti in myInstitx" :key="unainsti.id" :value="unainsti.id">{{unainsti.institucion}}</option>
  </f7-list-input>
      </f7-list>
    </f7-col>
      </f7-row>
  <f7-row class="clpreadonly">
    <f7-col width="100" medium="50">
      <f7-list class="preprint no-margin-top no-margin-bottom">
         <f7-list-input
        label="Profesional Responsable"
        type="text"
        placeholder="Profesional Responsable"
        :value="myName"
        readonly
      ></f7-list-input>
      <f7-list-input
        label="Región"
        type="text"
        placeholder="Región"
        :value="myOrigen.region"
        readonly
      ></f7-list-input>
      <f7-list-input
        label="Provincia"
        type="text"
        placeholder="Provincia"
        :value="myOrigen.provincia"
        readonly
      ></f7-list-input>
      <f7-list-input
        label="Comuna"
        type="text"
        placeholder="Comuna"
        :value="myOrigen.comuna"
        readonly
      ></f7-list-input>
       <f7-list-input
        label="Dirección"
        type="text"
        placeholder="Dirección"
        :value="myOrigen.direccion"
        readonly
      ></f7-list-input>

      </f7-list>
    </f7-col>
    <f7-col width="100" medium="50">
      <f7-list class="preprint no-margin-top no-margin-bottom">
         <f7-list-input
        label="Institución"
        type="text"
        placeholder="Institución"
        :value="myOrigen.institucion"
        readonly
      ></f7-list-input>
       <f7-list-input
        label="Unidad"
        type="text"
        placeholder="Unidad"
        :value="myOrigen.unidad"
        readonly
      ></f7-list-input>
      <f7-list-input
        label="Correo electrónico"
        type="text"
        placeholder="Correo electrónico"
        :value="profile_userInfo.email"
        readonly
      ></f7-list-input>
      <f7-list-input
        label="Telefono"
        type="text"
        placeholder="Telefono"
        :value="myOrigen.fono"
        readonly
      ></f7-list-input>
      <f7-list-input
        label="Fax"
        type="text"
        placeholder="Fax"
        :value="myOrigen.fax"
        readonly
      ></f7-list-input>
      </f7-list>
    </f7-col>
  </f7-row>
    </f7-block>


    <f7-block>
     <p>IMPORTANTE: EN CASO DE SER NECESARIO, DEBERÁ LLENAR PARTE II DEL FORMULARIO EN EL PDF GENERADO.</p>
    </f7-block>
<!-- DE AQUI A ABAJO ES LEGACY -->



<f7-block>
  <f7-button fill raised @click="firmarycerrar('f2')" >Firmar y Cerrar</f7-button>
    <f7-button fill raised @click="printme()" >Firmar e Imprimir</f7-button>

</f7-block>

  </f7-page>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import {formasmixin} from '../components/formasmixin';
import {paxhelpersmixin} from '../components/paxhelpersmixin';

import fechcomp from '../components/fechcomp.vue';

export default {
     mixins: [formasmixin,paxhelpersmixin],
     components:{fechcomp},

  data(){
    return{
      formaName: 'FORMULARIO DE NOTIFICACIÓN E INVESTIGACIÓN 2019-nCoV',
        comorbilidades:[
      {nam:'Cardiopatía Cróniva',
      id: 'cardiocron'},
      {nam:'Enfermedad Neurológica Crónica',
      id: 'enfrneurocron'},
      {nam:'Enfermedad Pulmonar Crónica',
      id: 'enfpulmcron'},
      {nam:'Enfermedad Renal Crónica',
      id: 'enfrenalcron'},
      {nam:'Diabetes',
      id: 'diabet'},
      {nam:'Asma',
      id: 'asma'},
      {nam:'Enfermedad Hepática Crónica',
      id: 'enfhepcron'},
      {nam:'Inmunocomprometido por enfermedad o tratamiento',
      id: 'inmunocompr'},
     ],
      riskfactors:[
      {nam:'Tabaquismo',
      id: 'tabaquismo'},
      {nam:'Uso de vapeadores',
      id: 'vapeadores'},
      {nam:'Alcoholismo',
      id: 'alcoholismo'},
     ],
    }
  },
    computed:{
		  ...mapState(['focusedForm','profile_userInfo','misprivx','sintomasf1','sintomasf2']),
      ...mapGetters(['myName','myInstitx']),
       prefferedInsti(){
         return  this.focusedForm.prfi_dos || Object.keys(this.myInstitx)[0];
       },
       myOrigen(){
         return this.myInstitx[this.prefferedInsti] || {};
       }

  },
  mounted(){
    console.log('focusedForm',this.focusedForm);
  },
  beforeDestroy(){
        this.$store.commit('setFormtoHolder',{what:this.focusedForm.fuid,to:this.focusedForm});
  },
  methods:{
    printme(sendmail=false){
      let ptopromes=[];
        let firma = {
          who: this.profile_userInfo.uid,
          whon: this.profile_userInfo.displayName,
          em: this.profile_userInfo.email,
          cuan: Date.now()
        };
       if(!this.focusedForm.f2){
          if(!sendmail){
          this.$f7.dialog.preloader('Firmando Generando PDF...');
          }
         ptopromes.push(this.$firebase.database().ref('forms/'+this.focusedForm.fuid+'/f2').set(firma));
       }
       else{
         ptopromes.push(true);
          if(!sendmail){
          this.$f7.dialog.preloader('Generando PDF...');
          }
       }
      return Promise.all(ptopromes).then(multisnasp=>{
         if(!this.focusedForm.f2){
            this.focusedForm.f2=firma;
            this.$store.commit('setFormtoHolder',{what:this.focusedForm.fuid,to:this.focusedForm});
         }
         this.$firebase.analytics().logEvent('imprimiendo_f2');
        return this.$firebase.auth().currentUser.getIdToken();
      }).then((token)=> {
          let req = new XMLHttpRequest();
          req.onload = (e)=> {
              this.$f7.dialog.close();
              if (e.target.status == 200) {
                if(sendmail){
                  console.log('ok');
                  this.$f7.dialog.alert('Email enviado');
                }
                else{
                     // Create a new Blob object using the 
                  //response data of the onload object
                  var blob = new Blob([e.target.response], {type: 'application/pdf'});
                  //Create a link element, hide it, direct 
                  //it towards the blob, and then 'click' it programatically
                  let a = document.createElement("a");
                  a.style = "display: none";
                  document.body.appendChild(a);
                  //Create a DOMString representing the blob 
                  //and point the link element towards it
                  let url = window.URL.createObjectURL(blob);
                  a.href = url;
                  a.classList.add("external");
                  a.download = 'notifinv.pdf';
                  //programatically click the link to trigger the download
                  a.click();
                  //release the reference to the file by revoking the Object URL
                  window.URL.revokeObjectURL(url);
                }
              }else{
                  //deal with your error state here
                   this.$f7.dialog.close();
                   console.log('Error al descargar',e);
              }
          };
          req.onerror = ()=> {
              this.$f7.dialog.close();
            console.log('Error al request');
          };
           if(sendmail){
            req.open('GET', this.$apiurl+'mailerf2/'+this.focusedForm.fuid+'/'+sendmail, true);
          }
          else{
            req.open('GET', this.$apiurl+'printerf2/'+this.focusedForm.fuid, true);
          }
          req.setRequestHeader('Authorization', 'Bearer ' + token);
          req.responseType = 'blob';
          req.send();
      }).catch(error=>{
        this.$f7.dialog.close();
        this.$f7.dialog.alert(error.message || error, error.code);
      });
    }
  }
}
</script>
<style>
.preprint .item-inner{
  padding-top: 2px;
    padding-bottom: 2px;
}


.soyformaone .item-content.item-input {
background: #f1f1f1;
}

.soyformaone  .item-input-wrap{
  background:#FFF;
  border-radius: 7px;
}
.soyformaone  .item-input-wrap input, .soyformaone  .item-input-wrap select{
  padding: 3px;
}
.soyformaone .clpreadonly  .item-input-wrap input:read-only{
  background: #f1f1f1!important;
}


.alrededor{
  padding: 4px;
    border: 1px solid #0279ff;
    border-radius: 10px;
}
.fgrislist li{
      background: #f1f1f1;
}
</style>