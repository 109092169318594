<template>
  <f7-page name="paciente" >
    <!-- Top Navbar -->
    

    <f7-navbar  >
     
      <f7-nav-title sliding :title="focusedForm.code" subtitle="Formulario Paciente"></f7-nav-title>
       <f7-nav-right>
        <f7-link  icon-material="exit_to_app" @click="cerrarPaciente()">Salir</f7-link>
      </f7-nav-right>
     
    </f7-navbar>
<f7-list v-if="!grabado" class="no-margin-top">
  <f7-list-item divider title="Datos demográficos"></f7-list-item>
  <f7-list-input
    label="Tipo Identificación"
    type="select"
    defaultValue="RUT"
    placeholder="Please choose..."
    :value="paxinfo.tipoid"
    @input="paxinfo.tipoid = $event.target.value"
    @change="nuevoTipoId()"
  >
    <option value="RUT">RUT</option>
    <option value="Passaporte-otro">Pasaporte u otro</option>
  </f7-list-input>

   <f7-list-input
    :label="paxinfo.tipoid"
    type="text"
    placeholder="Número identificador"
    :value="paxinfo.rutid"
     @input="formatRut($event.target.value)"
     error-message="RUT no parece válido!"
     @blur="validaRut()"
     :error-message-force="paxinfo.rutid.trim().length>0 && paxinfo.rutinvalido"
    clear-button
  >
  </f7-list-input>

   <f7-list-input
    label="Nombres"
    type="text"
    placeholder="Nombres"
    :value="paxinfo.nombres"
    @input="paxinfo.nombres = $event.target.value"
     @blur="grabarEsto('nombres',paxinfo.nombres)"
    clear-button
  >
  </f7-list-input>

    <f7-list-input
    label="Apellido Paterno"
    type="text"
    placeholder="Apellido Paterno"
    :value="paxinfo.apepat"
    @input="paxinfo.apepat = $event.target.value"
    @blur="grabarEsto('apepat',paxinfo.apepat)"
    clear-button
  >
  </f7-list-input>

    <f7-list-input
    label="Apellido Materno"
    type="text"
    placeholder="Apellido Materno"
    :value="paxinfo.apemat"
    @input="paxinfo.apemat = $event.target.value"
    @blur="grabarEsto('apemat',paxinfo.apemat)"
    clear-button
  >
  </f7-list-input>

  <f7-list-input
    label="Sexo"
    type="select"
    :value="paxinfo.sexo"
    @input="paxinfo.sexo = $event.target.value"
    @change="grabarEsto('sexo',paxinfo.sexo)"
    placeholder="Seleccione..."
  >
  <option value="">Seleccione</option>
    <option value="Masculino">Masculino</option>
    <option value="Femenino">Femenino</option>
  </f7-list-input>
  <li>
      <div class="item-content item-input">
        <div class="item-inner">
           <div class="item-title item-label">Fecha de nacimiento</div>
          <div class="item-input-wrap">
            <input type="text" placeholder="Fecha de nacimiento" 
          @change="updatingFecha('fechanac', $event.target.value)" 
          readonly="readonly" id="fecha-nac-paciente"/>
          </div>
        </div>
      </div>
    </li>

      <f7-list-input
    label="Previsión"
    type="text"
    placeholder="FONASA/ISAPRE(Cual?)"
    :value="paxinfo.prevision"
    @input="paxinfo.prevision = $event.target.value"
    @blur="grabarEsto('prevision',paxinfo.prevision)"
    clear-button
  >
  </f7-list-input>
     <f7-list-input
    label="Teléfono"
    type="number"
    placeholder="Fono/móvil"
    :value="paxinfo.fono"
    @input="paxinfo.fono = $event.target.value"
    @blur="grabarEsto('fono',paxinfo.fono)"
    clear-button
  >
  </f7-list-input>
   <f7-list-input
    label="Domicilio"
    type="text"
    placeholder="Domicilio"
    :value="paxinfo.domicilio"
    @input="paxinfo.domicilio = $event.target.value"
     @blur="grabarEsto('domicilio',paxinfo.domicilio)"
    clear-button
  >
  </f7-list-input>
          <f7-list-item :title="paxinfo.comuna || 'No definido'" header="Comuna: "  class="noshow" :footer="paxinfo.comuna?(paxinfo.provincia +' | '+ paxinfo.region):''" smart-select :smart-select-params="{ searchbar: true, searchbarPlaceholder: 'Use este BUSCADOR de Comunas', closeOnSelect: true}">
          <select @change="eligiendoComuna($event.target.value)" name="comuna" >
            <template v-for="region in comunas">
               <optgroup v-for="provincia in region.provincias" :key="region.region_number+provincia.name" :label="region.region+': '+provincia.name">
              <option v-for="comuna in provincia.comunas" :selected="selectedComuna==(comuna.name+'|'+provincia.name+'|'+region.region)" :key="comuna.code" :value="comuna.name+'|'+provincia.name+'|'+region.region">{{comuna.name}}</option>
              </optgroup>
            </template>
          </select>
        </f7-list-item>

 <f7-list-input
    label="Ruralidad"
    type="select"
    :value="paxinfo.ruralidad"
    @input="paxinfo.ruralidad = $event.target.value"
     defaultValue="Zona Urbana"
    placeholder="Seleccione..."
     @change="grabarEsto('ruralidad',paxinfo.ruralidad)"
  >
    <option value="Zona Urbana">Zona Urbana</option>
    <option value="Zona Rural">Zona Rural</option>
  </f7-list-input>



    <f7-list-input
    label="Dirección Trabajo/Escuela"
    type="text"
    placeholder="Dirección Trabajo/Escuela"
    :value="paxinfo.direccion"
    @input="paxinfo.direccion = $event.target.value"
    @blur="grabarEsto('direccion',paxinfo.direccion)"
    clear-button
  >
  </f7-list-input>

   <f7-list-input
    label="Ocupación"
    type="text"
    placeholder="Ocupación"
    :value="paxinfo.ocupacion"
    @input="paxinfo.ocupacion = $event.target.value"
     @blur="grabarEsto('ocupacion',paxinfo.ocupacion)"
    clear-button
  >
  </f7-list-input>

  <f7-list-item divider title="Antecedentes clínicos / Epidemiológicos"></f7-list-item>
<li>
      <div class="item-content item-input">
        <div class="item-inner">
           <div class="item-title item-label">Fecha de Inicio de Síntomas</div>
          <div class="item-input-wrap">
            <input type="text" placeholder="Fecha de Inicio de Síntomas" 
           @change="updatingFecha('fechainisint', $event.target.value)" 
          readonly="readonly" id="fecha-fechainisint"/>
          </div>
        </div>
      </div>
    </li>

    <li>
      <div class="item-content item-input">
        <div class="item-inner">
           <div class="item-title item-label">Fecha Primera Consulta</div>
          <div class="item-input-wrap">
            <input type="text" placeholder="Fecha Primera Consulta" 
           @change="updatingFecha('fechaprimconsult', $event.target.value)" 
          readonly="readonly" id="fecha-primconsult"/>
          </div>
        </div>
      </div>
    </li>
     <f7-list-input
    label="Lugar primera consulta"
    type="text"
    placeholder="Lugar primera consulta"
    :value="paxinfo.lugarPimeraConsulta"
    @input="paxinfo.lugarPimeraConsulta = $event.target.value"
     @blur="grabarEsto('lugarPimeraConsulta',paxinfo.lugarPimeraConsulta)"
    clear-button
  >
  </f7-list-input>
 

       <f7-list-item>
    <span>Trabajador Avícola o Granja Cerdos </span>
    <f7-toggle 
    
            @change="toglea($event,'trabajador_avicolaocerdo')"
            :checked="paxinfo.trabajador_avicolaocerdo"
            class="toggle"
        ></f7-toggle>
        </f7-list-item>  

          <f7-list-item v-show="paxinfo.sexo=='Femenino'">
    <span>Embarazo </span>
    <f7-toggle 
            @change="toglea($event,'embarazo')"
            :checked="paxinfo.embarazo"
            class="toggle"
        ></f7-toggle>
        </f7-list-item>  

           <f7-list-input
           v-show="paxinfo.embarazo"
    label="Semanas de Gestación"
    type="number"
    placeholder="Semanas de Gestación completas"
    :value="paxinfo.semgestacion"
    @input="paxinfo.semgestacion = $event.target.value"
     @blur="grabarEsto('semgestacion',paxinfo.semgestacion)"
    clear-button
  >
  </f7-list-input>

  <f7-list-item>
    <span>Viajó al extranjero en los 14 dias previos al inicio de síntomas </span>
    <f7-toggle 
            @change="toglea($event,'viajex')"
            :checked="paxinfo.viajex"
            class="toggle"
        ></f7-toggle>
        </f7-list-item>
     <li v-show="paxinfo.viajex">
      <div class="item-content item-input">
        <div class="item-inner">
           <div class="item-title item-label">Fecha Inicio Viaje</div>
          <div class="item-input-wrap">
            <input type="text" placeholder="Fecha Inicio Viaje" 
           @change="updatingFecha('inicioviaje', $event.target.value)" 
          readonly="readonly" id="fecha-inicioviaje"/>
          </div>
        </div>
      </div>
    </li>
    <li v-show="paxinfo.viajex">
      <div class="item-content item-input">
        <div class="item-inner">
           <div class="item-title item-label">Fecha Retorno al Pais</div>
          <div class="item-input-wrap">
            <input type="text" placeholder="Fecha Retorno al Pais" 
           @change="updatingFecha('finviaje', $event.target.value)" 
          readonly="readonly" id="fecha-finviaje"/>
          </div>
        </div>
      </div>
    </li>
    <f7-list-item v-show="paxinfo.viajex" divider title="Ciudades y Paises visitados"></f7-list-item>
    <f7-list-item v-for="(ciudadpais,idx) in paxinfo.ciudadesPaises" :key="idx" :footer="ciudadpais.fechas.join(' al ')" :title="ciudadpais.ciudad" :after="ciudadpais.pais" ></f7-list-item>
    <f7-list-item v-show="paxinfo.viajex" title="Agregar pais,ciudad y fecha" @click="viajesPopupOpened = true" >
      <f7-icon material="add_circle" slot="after"></f7-icon>
    </f7-list-item>
 <f7-list-item divider title="Contactos"></f7-list-item>
     <f7-list-item>
    <span>Tuvo contacto con personas enfermas o que hayan fallecido de enfermedades respiratorias</span>
    <f7-toggle 
    
            @change="toglea($event,'contactoenfermas')"
            :checked="paxinfo.contactoenfermas"
            class="toggle"
        ></f7-toggle>
        </f7-list-item>  

  <f7-list-input
  v-show="paxinfo.contactoenfermas"
    label="Lugar donde ocurrió el contacto"
    type="text"
    placeholder="Lugar donde ocurrió el contacto"
    :value="paxinfo.lugar_contactoenfermas"
    @input="paxinfo.lugar_contactoenfermas = $event.target.value"
     @blur="grabarEsto('lugar_contactoenfermas',paxinfo.lugar_contactoenfermas)"
    clear-button
  >
  </f7-list-input>


       <f7-list-item>
    <span>Ha visitado mercados animales</span>
    <f7-toggle 
    
            @change="toglea($event,'mercadosanimales')"
            :checked="paxinfo.mercadosanimales"
            class="toggle"
        ></f7-toggle>
        </f7-list-item>  

  <f7-list-input
  v-show="paxinfo.mercadosanimales"
    label="Lugar mercado animal"
    type="text"
    placeholder="Lugar mercado animal"
    :value="paxinfo.lugar_mercadoanimal"
    @input="paxinfo.lugar_mercadoanimal = $event.target.value"
     @blur="grabarEsto('lugar_mercadoanimal',paxinfo.lugar_mercadoanimal)"
    clear-button
  >
  </f7-list-input>

 <f7-list-item>
    <span>Ha estado en contacto con animales silvestres enfermos (vivos o muertos)?</span>
    <f7-toggle 
    
            @change="toglea($event,'contactoanimalesenfermos')"
            :checked="paxinfo.contactoanimalesenfermos"
            class="toggle"
        ></f7-toggle>
        </f7-list-item>  

<f7-list-item>
    <span>Es trabajador de salud</span>
    <f7-toggle 
    
            @change="toglea($event,'estrabajadorsalud')"
            :checked="paxinfo.estrabajadorsalud"
            class="toggle"
        ></f7-toggle>
        </f7-list-item>  

  <f7-list-input
  v-show="paxinfo.estrabajadorsalud"
    label="Establecimiento"
    type="text"
    placeholder="Establecimiento"
    :value="paxinfo.lugarTrabajadorSalud"
    @input="paxinfo.lugarTrabajadorSalud = $event.target.value"
      @blur="grabarEsto('lugarTrabajadorSalud',paxinfo.lugarTrabajadorSalud)"
    clear-button
  >
  </f7-list-input>

</f7-list>
<f7-block v-if="!grabado"><f7-button raised fill @click="heterminado()">He terminado</f7-button></f7-block>
<f7-block v-else class="text-align-center">
  <p>Si ya completó el formulario, acercarse a su tratante e indicar el Número de formulario: <br/> <span class="lecodep">{{this.focusedForm.code}}</span></p>
  <f7-block>
  <f7-row>
    <f7-col class="margin-top margin-bottom" width="100" medium="33"><f7-button :href="'mailto:indique@email.com?subject=Codigo '+focusedForm.code+'&body=Codigo corona es '+focusedForm.code" target="_blank" external outline >Enviar por e-mail</f7-button></f7-col>
    <f7-col class="margin-top margin-bottom" width="100" medium="33"><f7-button :href="'https://wa.me/?text='+focusedForm.code" target="_blank" external outline >Enviar por whatsapp</f7-button></f7-col>
     <f7-col class="margin-top margin-bottom" width="100" medium="33"><f7-button outline @click="cerrarPaciente()" >Salir de la APP</f7-button></f7-col>
  </f7-row>
  </f7-block>
  

  </f7-block>
 
 <f7-popup class="viajes-popup" :opened="viajesPopupOpened" @popup:closed="viajesPopupOpened = false">
      <f7-page>
        <f7-navbar title="Agregar Pais, Ciudad y Fecha">
          <f7-nav-right>
            <f7-link popup-close>Cancelar</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list class="no-margin-top">
          <f7-list-item divider title="Detalles del viaje"></f7-list-item>
            <f7-list-input
            label="Pais visitado"
            type="text"
            placeholder="Pais visitado"
            :value="paisVisitaToAdd.pais"
            @input="paisVisitaToAdd.pais = $event.target.value"
            clear-button
          >
          </f7-list-input>
           <f7-list-input
            label="Ciudad visitada"
            type="text"
            placeholder="Ciudad visitada"
            :value="paisVisitaToAdd.ciudad"
            @input="paisVisitaToAdd.ciudad = $event.target.value"
            clear-button
          >
          </f7-list-input>
          <li >
      <div class="item-content item-input">
        <div class="item-inner">
           <div class="item-title item-label">Fechas Visita Ciudad</div>
          <div class="item-input-wrap">
            <input type="text" placeholder="Fechas Visita Ciudad" 
          readonly="readonly" id="addviaje"/>
          </div>
        </div>
      </div>
    </li>
        </f7-list>
        <f7-block><f7-button @click="addEstaCiudad()" outline>Agregar registro</f7-button></f7-block>
      </f7-page>
    </f7-popup>

  </f7-page>
</template>
<script>
import {mapState, mapGetters} from 'vuex';
import { validate, format, clean} from 'rut.js';
import {comunashelper} from '../components/comunashelper';

export default {
     mixins: [comunashelper],
  data(){
    return{
      grabado:false,
      paxinfo:{
        tipoid: 'RUT',
        rutid: '',
        rutinvalido: false,
        nombres: '',
        apepat: '',
        apemat: '',
        sexo: null,
        fechanac: null,
        prevision: '',
        fono: null,
        domicilio: '',
        comuna:null,
        provincia:null,
        region:null,
        ruralidad:'Zona Urbana',
        direccion: '',
        ocupacion: '',
        fechainisint: null,
        fechaprimconsult: null,
        lugarPimeraConsulta: '',
        trabajador_avicolaocerdo: false,
        embarazo: false,
        semgestacion: null,
        viajex: false,
        inicioviaje: null,
        finviaje: null,
        ciudadesPaises:[],
        contactoenfermas: false,
        lugar_contactoenfermas: '',
        mercadosanimales: false,
        lugar_mercadoanimal: '',
        contactoanimalesenfermos:false,
        estrabajadorsalud: false,
        lugarTrabajadorSalud: ''
      },
      paisVisitaToAdd:{
        pais:'',
        ciudad: ''
      },
      calRef_nacimiento: null,
      calRef_sintomas: null,
      calRef_primconsult: null,
      viajesPopupOpened: false,
      toastBottom: null
    }
  },
    computed:{
    ...mapState(['defaultAvatar','profile_userInfo','focusedForm']),
     ...mapGetters(['myAvatar','myName']),
     selectedComuna(){
       return this.paxinfo.comuna?(this.paxinfo.comuna+'|'+this.paxinfo.provincia+'|'+this.paxinfo.region):'a|b|c';
     }
  },
  created(){
    // this.$f7.progressbar.show('blue');
    //  this.$f7.progressbar.hide();

    //asign loaded values
    this.paxinfo=Object.assign({},this.paxinfo,this.focusedForm);
  
  },
  mounted(){
       this.toastBottom = this.$f7.toast.create({
          text: 'Datos guardados',
          closeTimeout: 2000,
        });
    this.calRef_nacimiento = this.$f7.calendar.create({
      inputEl: '#fecha-nac-paciente',
      dateFormat: 'dd-mm-yyyy',
      maxDate: new Date(),
      closeOnSelect: true
    });
      this.calRef_sintomas = this.$f7.calendar.create({
      inputEl: '#fecha-fechainisint',
      dateFormat: 'dd-mm-yyyy',
      maxDate: new Date(),
      closeOnSelect: true
    });
     this.calRef_primconsult = this.$f7.calendar.create({
      inputEl: '#fecha-primconsult',
      dateFormat: 'dd-mm-yyyy',
      maxDate: new Date(),
      closeOnSelect: true
    });

     this.calRef_inicioviaje = this.$f7.calendar.create({
      inputEl: '#fecha-inicioviaje',
      dateFormat: 'dd-mm-yyyy',
      maxDate: new Date(),
      closeOnSelect: true
    });

    this.calRef_finviaje = this.$f7.calendar.create({
      inputEl: '#fecha-finviaje',
      dateFormat: 'dd-mm-yyyy',
      maxDate: new Date(),
      closeOnSelect: true
    });

    this.calRef_addviaje = this.$f7.calendar.create({
      inputEl: '#addviaje',
      dateFormat: 'dd-mm-yyyy',
      maxDate: new Date(),
      rangePicker: true,
      closeOnSelect: true
    });
      //ahora setear las fechas, if any
     let fechasToIterate=[
       {comp:'calRef_nacimiento',
       prop: 'fechanac'},
        {comp:'calRef_sintomas',
       prop: 'fechainisint'},
        {comp:'calRef_primconsult',
       prop: 'fechaprimconsult'},
        {comp:'calRef_inicioviaje',
       prop: 'inicioviaje'},
      {comp:'calRef_finviaje',
       prop: 'finviaje'},
       //inicioviaje
     ];
     fechasToIterate.forEach(uncalcomp => {
       if(this.paxinfo[uncalcomp.prop]){
         let fechajs=this.$moment(this.paxinfo[uncalcomp.prop], "DD-MM-YYYY").toDate();
         this[uncalcomp.comp].setValue([fechajs]);
       }
     });
  },
  methods:{
    heterminado(){
      this.$f7.dialog.alert('Sus datos han sido guardados de forma exitosa. Cuando su tratante lo solicite, entregue el código  "'+this.focusedForm.code+'".',()=>{
        this.grabado=true;
        this.$firebase.analytics().logEvent('paciente_termina_formulario');
      });
    },
    updatingFecha(cualprop, elvalor){
      if(this.paxinfo[cualprop]!=elvalor){
         console.log('Actualizando fecha'+cualprop+' a '+elvalor);
        this.grabarEsto(cualprop,elvalor);
      }
      if(cualprop=='fechanac'){
        console.log('evaluando si es menor de edad',elvalor);
        	let b = this.$moment(elvalor, "DD-MM-YYYY");
          let a = this.$moment();
          let years = a.diff(b, 'year');
          console.log('tiene '+years);
          if(years<18 && !this.paxinfo.representante){
            this.requestRepe();
          }
      }
    },
    requestRepe(){
      this.$f7.dialog.prompt('Es necesario que tu representante legal autorice el ingreso de datos conociendo la politica de privacidad. El representante debe ingresar aquí su nombre completo y rut.','Menor de edad', (name)=> {
          if(!name || name.trim()==''){
            this.$f7.dialog.alert('Debe indicar un nombre y rut',()=>{
              this.requestRepe();
            });
          }
          else{
             this.grabarEsto('representante',name);
          }
        },()=>{
           this.$f7.dialog.alert('Debe indicar un nombre y rut',()=>{
              this.requestRepe();
            });
        });
    },
    addEstaCiudad(){
      let fechas=this.calRef_addviaje.getValue();
      let neofechas=fechas.map(unafech=>{
        return this.$moment(unafech).format('DD-MM-YYYY');
      });
      console.log(fechas,this.paisVisitaToAdd);
      if(!this.paisVisitaToAdd.pais || !this.paisVisitaToAdd.ciudad || (!fechas || fechas.length==0 )){
        return this.$f7.dialog.alert('Debe indicar ciudad,pais y fechas','Datos faltantes');
      }
      let toadd={
        pais: this.paisVisitaToAdd.pais,
        ciudad: this.paisVisitaToAdd.ciudad,
        fechas: neofechas,
      };
        console.log('agregando esta ciudad',toadd);
        this.paxinfo.ciudadesPaises.push(toadd);
        this.viajesPopupOpened = false;
        this.paisVisitaToAdd.pais='';
        this.paisVisitaToAdd.ciudad='';
        this.calRef_addviaje.setValue([]);
         this.grabarEsto('ciudadesPaises',this.paxinfo.ciudadesPaises);
    },
      toglea(event,propiedad){
      let targetchecked=event.target.checked;
      this.paxinfo[propiedad]=targetchecked;
      this.grabarEsto(propiedad,targetchecked);
    },
      eligiendoComuna(comuna){
        console.log('eligiendo comuna',comuna);
        let linfo=comuna.split('|');
        console.log(linfo);
        this.paxinfo.comuna=linfo[0];
         this.paxinfo.provincia=linfo[1];
         this.paxinfo.region=linfo[2];
         this.updateThese({
           comuna:this.paxinfo.comuna,
           provincia:this.paxinfo.provincia,
           region:this.paxinfo.region,
           });
    },
    nuevoTipoId(){
      console.log('nuevo tipo de iD');
      if(this.paxinfo.tipoid==='RUT'){
        this.formatRut(this.paxinfo.rutid);
      }
      else{
          this.paxinfo.rutid = clean(this.paxinfo.rutid);
          if(this.paxinfo.rutinvalido){
            this.validaRut();
          }
      }
     this.grabarEsto('tipoid',this.paxinfo.tipoid);

    },
    grabarEsto(propiedad,valor){
       //inicio grabado
      this.$f7.progressbar.show('blue');
      this.$firebase.database().ref('preforms/'+this.profile_userInfo.uid+'/'+propiedad).set(valor).then(()=>{
        this.$f7.progressbar.hide();
        this.toastBottom.open();
      }).catch(error=>{
        this.$f7.progressbar.hide();
        this.$f7.dialog.alert(error.message, error.code);
      });
    },
    updateThese(whatObjetTo){
       //inicio grabado
      this.$f7.progressbar.show('blue');
      this.$firebase.database().ref('preforms/'+this.profile_userInfo.uid).update(whatObjetTo).then(()=>{
        this.$f7.progressbar.hide();
        this.toastBottom.open();
      }).catch(error=>{
        this.$f7.progressbar.hide();
        this.$f7.dialog.alert(error.message, error.code);
      });
    },
    formatRut(linput){
      if(this.paxinfo.tipoid === 'RUT'){
        this.paxinfo.rutid = format(linput); //Separa el rut con puntos, guion y cambia a mayúscula
        if(this.paxinfo.rutinvalido){
          this.validaRut();
        }
      }
      else{
        this.paxinfo.rutid = linput;
      }
    },
      validaRut(){
      if(this.paxinfo.tipoid === 'RUT'){
        this.paxinfo.rutinvalido = !validate(this.paxinfo.rutid);
      }
      this.grabarEsto('rutid',this.paxinfo.rutid);
    },
    cerrarPaciente(){
       this.$f7.dialog.confirm('Guarde el código "'+this.focusedForm.code+'" para entregar a su tratante. ','Está seguro que desea salir?', ()=> {
          this.logout();
        });
    },
       logout() {
        console.log('Logging out');
        this.$f7.dialog.preloader('Cerrando sesión...');
         this.$store.commit('setObjTo',{what:'misprivx',to:{}});
           this.$store.commit('setObjTo',{what:'profile_userInfo',to:{}});
           this.$store.commit('setObjTo',{what:'focusedForm',to:{}});
        this.$firebase.auth().signOut().then(()=> {
          console.log('Sign-out successful.');
          this.$f7.dialog.close();
        }).catch((error)=> {
          this.$f7.dialog.close();
          console.log('Sign-out error.', error);
        });
      }
  }
}
</script>
<style>
.noshow .smart-select .item-after{
  color:#FFF!important;
}
.lecodep{
  font-size:22px;
  font-weight: bold;
}
</style>