import { format } from 'rut.js';
import {mapState} from 'vuex';
export const paxhelpersmixin = {
	computed:{
		...mapState(['misprivx'])
	},
    methods: {
			requestMailSend(){
				let mailtosend=this.misprivx.mailto || null;
				let frase=mailtosend?'Confirme':'Indique';
				this.$f7.dialog.prompt(frase+' email de destino',  (nombre)=> {
					let fixedNombre=nombre.trim();
					 if(fixedNombre==""){
						return this.$f7.dialog.alert('Debes indicar email');
					}
					if(mailtosend!=fixedNombre){
						this.saveMailToandSend(fixedNombre);
					}
					else{
						this.$f7.dialog.preloader('Generando PDF y Enviando...');
						this.printme(fixedNombre);
					}
					},
				()=>{}
					,
						(mailtosend)
					);
			},
			saveMailToandSend(newmailTo){
				this.$f7.dialog.preloader('Generando PDF y Enviando...');
				this.$firebase.database().ref('privx/'+this.profile_userInfo.uid+'/mailto').set(newmailTo).then(()=> {
					// Update successful.
					 this.$store.commit('setOrigenPropTo',{what:'mailto',to:newmailTo});
					this.printme(newmailTo);
				}).catch((error)=> {
					// An error happened.
					this.$f7.dialog.close();
					this.$f7.dialog.alert(error.message);
				});
			},
		formatId(forma){
			let lid=forma.rutid;
				if(forma.tipoid === 'RUT'){
						lid = format(forma.rutid)
				}
				return lid;
		},
		suage(fechanax){
			if(!fechanax){
				return 'Fecha de nacimiento no indicada';
			}
			let momento=this.$moment(fechanax, "DD-MM-YYYY");
			let anos= this.$moment().diff(momento, 'years');
			if(anos>=2){
				return anos+' a';
			}
			else{
					let meses= this.$moment().diff(momento, 'months');
					if(meses>=2){
						return meses+ 'm';
					}
					else{
						return this.$moment().diff(momento, 'days')+ 'd';
					}
			}
		},
		suageparts(fechanax){
			if(!fechanax){
				return 'Fecha de nacimiento no indicada';
			}
			let b = this.$moment(fechanax, "DD-MM-YYYY");
			let a = this.$moment();
			let years = a.diff(b, 'year');
			b.add(years, 'years');
			let months = a.diff(b, 'months');
			b.add(months, 'months');
			let days = a.diff(b, 'days');
			return (years + ' años ' + months + ' meses ' + days + ' dias');
		},
		lastNamesAndNames(forma){
			return (forma.apepat || 'No registrado')+ (forma.apemat?(','+forma.apemat):'')+', '+(forma.nombres || 'No registrado')
		}
    }
}