
import HomePage from '../pages/home.vue';
import Profsalud from '../pages/profsalud.vue';
import Paciente from '../pages/paciente.vue';
import formainstitution from '../pages/formainstitution.vue';
import formaone from '../pages/formaone.vue';
import formatwo from '../pages/formatwo.vue';

import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/profsalud/',
    component: Profsalud,
  },
  {
    path: '/paciente/',
    component: Paciente,
  },
  {
    path: '/formaone/',
    component: formaone,
  },
  {
    path: '/formatwo/',
    component: formatwo,
  },
  {
    path: '/formainstitution/',
    component: formainstitution,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
