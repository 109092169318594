import { mapState } from 'vuex';

export const formasmixin = {
  data() {
    return {
      toastBottom: null
    }
  },
  computed:{
    ...mapState(['focusedForm','profile_userInfo']),

},
  mounted(){
    this.toastBottom = this.$f7.toast.create({
       text: 'Datos guardados',
       closeTimeout: 2000,
     });
    },
  methods:{
    grabarEsto(propiedad){
      let valor=this.focusedForm[propiedad];
      //inicio grabado
      this.$f7.progressbar.hide();
     this.$f7.progressbar.show('blue');
     this.$firebase.database().ref('forms/'+this.focusedForm.fuid+'/'+propiedad).set(valor).then(()=>{
       this.$f7.progressbar.hide();
       this.toastBottom.open();
       if(propiedad=='prfi_dos' || propiedad=='prfi'){
        this.$store.commit('setObjTo',{what:'focusedForm',to:this.focusedForm});
       }
     }).catch(error=>{
       this.$f7.progressbar.hide();
       this.$f7.dialog.alert(error.message, error.code);
     });
   },
   checkonebox(target,cual) {
    console.log('cual',cual);
    const value = target.value;
    if(!this.focusedForm[cual]){
      this.focusedForm[cual]=[];
    }
    if (target.checked) {
      this.focusedForm[cual].push(value);
    } else {
      this.focusedForm[cual].splice(this.focusedForm[cual].indexOf(value), 1);
    }
    console.log(this.focusedForm[cual]);
    this.grabarEsto(cual);
  },
  checkoneradio(target,cual) {
    console.log('cual',cual);
    const value = target.value;
    this.focusedForm[cual]=value;
    console.log(this.focusedForm[cual]);
    this.grabarEsto(cual);
  },
  toglethis(event,propiedad){
    let targetchecked=event.target.checked;
    this.focusedForm[propiedad]=targetchecked;
    this.$store.commit('setObjTo',{what:'focusedForm',to:this.focusedForm});
    console.log(targetchecked,propiedad,this.focusedForm[propiedad]);
    this.grabarEsto(propiedad);
  },
  firmarycerrar(formanumber){
    //inicio grabado
    this.$f7.dialog.preloader('Firmando...');
    let firma = {
      who: this.profile_userInfo.uid,
      whon: this.profile_userInfo.displayName,
      em: this.profile_userInfo.email,
      cuan: Date.now()
    };
   this.$firebase.database().ref('forms/'+this.focusedForm.fuid+'/'+formanumber).set(firma).then(()=>{
     this.$f7.dialog.close();
     //actualizar mis formas
    // this.$store.commit('setObjTo',{what:'focusedForm',to:this.focusedForm});
    this.focusedForm[formanumber]=firma;
    this.$store.commit('setFormtoHolder',{what:this.focusedForm.fuid,to:this.focusedForm});
     this.$f7.views.main.router.back();
   }).catch(error=>{
    this.$f7.dialog.close();
     this.$f7.dialog.alert(error.message, error.code);
   });
 },
  }
}