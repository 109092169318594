<template>
    <f7-card :padding="false" class="card">
        <f7-card-header  class="soyblock border no-padding">
            <f7-list class="patienteinfox"  media-list>
				<f7-list-item 
					:class="{'skeleton-text skeleton-effect-blink':!forma}" 
					:title="forma?(lastNamesAndNames(forma)):'Apellido Paterno Materno, Nombre Prim y Seg'" 
					:subtitle="forma?formatId(forma):'16047654-0'"
					:text="forma?suage(forma.fechanac):'Edad...'"
					>
					<img class="paxavatar" :src="!forma?loadingAvatar:(forma.sexo=='Masculino'?'../static/img/masc.jpg':'../static/img/feme.jpg')" alt="avatar" slot="media" />
				</f7-list-item>
            </f7-list>
        </f7-card-header>
		<f7-card-content :padding="false" class="border">
			<f7-list class="formasinfo" media-list>
				<f7-list-item 
          :class="{sinfirma:(!forma || !forma.f1)}"
          header="Formulario de"
					title="Notif. inmediata y envio muestras a conf IRA grave y 2019-nCoV"
					:text="(forma && forma.f1)?('Firmado por '+forma.f1.whon):'Pendiente firma'"
          link="#"
          @click="iraForma('f1')"
				>
         <f7-icon v-if="(forma && forma.f1)" material="check_circle_outline" class="firmado" slot="after"></f7-icon>
        <f7-icon v-else material="error_outline" class="text-color-red" slot="after"></f7-icon>
				</f7-list-item>


        <f7-list-item 
         :class="{sinfirma:(!forma || !forma.f2)}"
        header="Formulario de"
					title="Notificación e Investigación 2019-nCoV"
				  :text="(forma && forma.f2)?('Firmado por '+forma.f2.whon):'Pendiente firma'"
          link="#"
          @click="iraForma('f2')"
				>
       <f7-icon v-if="(forma && forma.f2)" material="check_circle_outline" class="firmado" slot="after"></f7-icon>
        <f7-icon v-else material="error_outline" class="text-color-red" slot="after"></f7-icon>
				</f7-list-item>
			</f7-list>
		</f7-card-content>
	</f7-card>
</template>

<script>
import { mapState } from 'vuex';
import { format} from 'rut.js';
import {paxhelpersmixin} from '../components/paxhelpersmixin';

export default {
  mixins: [paxhelpersmixin],
    name: 'pendiente',
	props: ['fuid'],
	
    data(){
        return {
         
        }
	},
	
    computed:{
      ...mapState(['loadingAvatar']),
      forma(){
        return this.$store.state.allforms[this.fuid] || {};
      }
	},
    mounted(){
     //pedir info
     this.$firebase.database().ref('forms/'+this.fuid).once('value').then(snshot=>{
       let laofmra=snshot.val() || {};
       console.log(laofmra);
       //this.forma=Object.assign({},laofmra);
       //allforms
       //setFormtoHolder
       laofmra.fuid=this.fuid;
       this.$store.commit('setFormtoHolder',{what:this.fuid,to:laofmra});
        this.$store.commit('createdAtHolder',{what:this.fuid,to:laofmra.createdAt});
     }).catch(error=>{
       console.log(error);
       this.$f7.dialog.alert(error.message, error.code);
     });
	},
	
    beforeDestroy(){
      //apagar los observables, if any
     
    },
    	
    methods:{
      iraForma(cual){
        console.log('navegnado a forma '+cual);
        this.$store.commit('setObjTo',{what:'focusedForm',to:this.forma});
        if(cual=='f1'){
           this.$f7.views.main.router.navigate('/formaone/');
        }
        else if(cual=='f2'){
           this.$f7.views.main.router.navigate('/formatwo/');
        }
      }

  
	

	},
}
</script>
<style>
.patienteinfox img{
  width: 45px;
}
.formasinfo .item-title{
  white-space: unset!important;
}
.sinfirma .item-link{
  border-left: 3px solid #ffa5a5;
}
.firmado{
      color: #17c757;
}
</style>