<template>
      <div class="item-content item-input">
        <div class="item-inner">
           <div class="item-title item-label">{{name}}</div>
          <div class="item-input-wrap">
            <input type="text" :placeholder="name" 
          @change="updatingFecha(propiedad, $event.target.value)" 
          readonly="readonly" :id="'fp'+propiedad"/>
          </div>
        </div>
      </div>
</template>

<script>
import { mapState } from 'vuex';
import {formasmixin} from '../components/formasmixin';

export default {
    name: 'fechcomp',
         mixins: [formasmixin],
	props: ['name','propiedad','timePicker'],
	
    data(){
        return {
          calHolder:null
        }
	},
	
    computed:{
		  ...mapState(['focusedForm']),
	},
    mounted(){
      let startingDAate=this.focusedForm[this.propiedad]?[this.$moment(this.focusedForm[this.propiedad], (this.timePicker?"DD-MM-YYYY HH:mm":"DD-MM-YYYY")).toDate()]:[];
   this.calHolder = this.$f7.calendar.create({
      inputEl: '#fp'+this.propiedad,
      dateFormat: this.timePicker?'dd-mm-yyyy HH::mm':'dd-mm-yyyy',
      maxDate: new Date(),
      closeOnSelect: true,
      value: startingDAate,
      timePicker: this.timePicker || false
    });
	},
	
    beforeDestroy(){
      //apagar los observables, if any
     
    },
    	
    methods:{
       updatingFecha(cualprop, elvalor){
      if(this.focusedForm[cualprop]!=elvalor && elvalor){
        this.focusedForm[cualprop]=elvalor;
         console.log('Actualizando fecha'+cualprop+' a '+elvalor);
        this.grabarEsto(cualprop);
      }
    },

	},
}
</script>
<style>

</style>