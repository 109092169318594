import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        appName:'CoronaChile',
        appUrl: 'https://coronachile.com',
        appVersion: '0.4.6 BETA',
        activeTab:'/',
        loginOpened: true,
        profile_userInfo: {},
        misprivx:{
            forms:{},
            inst:{},
            rut: ''
        },
        loadingAvatar:'../static/img/ripple.gif',
        profile_defaultAvatar: '../static/img/default_original.png',
        tempOldAvatar:'',
        focusedForm:{},
        createdAtHolder:{},
        //gestor instituciones profesional salud
        focusedInstitucion:{},
        //megaHolder TODOS LOS FORMULARIOS
        allforms:{},
        //holders cehckboex reutilizabes
        sintomasf1:[
            {nam:'Fiebre sobre 38 C',
            id: 'fiebresobretroch'},
            {nam:'Dolor de garganta',
            id: 'dolorgargnt'},
            {nam:'Mialgia',
            id: 'mialgia'},
            {nam:'Neumonia',
            id: 'neuomni'},
            {nam:'Encefalitis',
            id: 'encefaltis'},
            {nam:'Tos',
            id: 'tos'},
            {nam:'Rinorrea/congestión Nasal',
            id: 'rinorrea'},
            {nam:'Dificultad Respiratoria',
            id: 'difresp'},
            {nam:'Hipotensión',
            id: 'hipotens'},
            {nam:'Cefalea',
            id: 'cefalea'},
            {nam:'Taquipnea',
            id: 'taquipnea'},
            {nam:'Hipoxia',
            id: 'hipoxia'},
            {nam:'Cianosis',
            id: 'cianosis'},
            {nam:'Deshidratación o rechazo alimentario (lactantes)',
            id: 'deshidratacion'},
            {nam:'Compromiso hemodinámica',
            id: 'comphemodinam'},
            {nam:'Consulta Repetida por cuadro respiratorio',
            id: 'consulrepetidresp'},
            {nam:'Enfermedad de Base',
            id: 'enfermbase'},
          ],
          sintomasf2:[
            {nam:'Fiebre',
            id: 'fiebresobretroch'},
            {nam:'Cefalea',
            id: 'cefalea'},
            {nam:'Dolor Torácico',
            id: 'doltoracico'},
            {nam:'Odinofagia',
            id: 'dolorgargnt'},
            {nam:'Tos',
            id: 'tos'},
            {nam:'Disnea',
            id: 'difresp'},
            {nam:'Taquipnea',
            id: 'taquipnea'},
            {nam:'Cianosis',
            id: 'cianosis'},
            {nam:'Mialgia',
            id: 'mialgia'},
            {nam:'Dolor Abdominal',
            id: 'dolorabdominal'},
            {nam:'Postración',
            id: 'postracion'},
            {nam:'Diarrea',
            id: 'diarrea'}
          ]
    },
    getters: {
        myAvatar: state => {
                return (state.profile_userInfo && state.profile_userInfo.photoURL) ? state.profile_userInfo.photoURL : state.profile_defaultAvatar;
        },
        myName: state=>{
            return state.profile_userInfo.displayName || 'Indique su nombre';
        },
        myOrigen: state=>{
            return state.misprivx.origen || {}
        },
        myformIds: state=>{
            let myfromsids = state.misprivx.forms || {};
            let arraydes=Object.keys(myfromsids) || [];
            arraydes.sort((a, b) => {
                const y = state.createdAtHolder[a];
                const x = state.createdAtHolder[b];
                if(x > y){
                    return 1
                }
                else if(x < y){
                    return -1
                }
                else{
                    return 0
                }
            });
            return arraydes;
        },
        myInstitx: state=>{
            return state.misprivx.inst || {}
        },
    },
    mutations: {
        setWhatTo: (state,payload)=>{
            state[payload.what] = payload.to;
        },
        mergeObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},state[payload.what],payload.to);
        },
        setObjTo:(state,payload)=>{
            state[payload.what]=Object.assign({},payload.to);
        },
        addInstitucionToPrivx:(state,payload)=>{
            let susinst=state.misprivx.inst;
            susinst[payload]=payload;
            state.misprivx.inst=Object.assign({},state.misprivx.inst,susinst)
        },
        createdAtHolder:(state,payload)=>{
            let nueo={};
            nueo[payload.what]=payload.to;
            state.createdAtHolder=Object.assign({},state.createdAtHolder,nueo);
        },
        setOrigenPropTo:(state,payload)=>{
            let nueo={};
            nueo[payload.what]=payload.to;
            state.misprivx=Object.assign({},state.misprivx,nueo);
        },
        setFormtoHolder:(state,payload)=>{
            let nueo={};
            nueo[payload.what]=payload.to;
            state.allforms=Object.assign({},state.allforms,nueo);
        },
        //ver la de abajo, siempre debisiese exisitr form, no es neccesario chequear
        addFormToPrivx:(state,payload)=>{
            if(!state.misprivx.forms){
                state.misprivx=Object.assign({},state.misprivx,{forms:{}})
            }
            let susformas=state.misprivx.forms || {};
            susformas[payload]=payload;
            state.misprivx.forms=Object.assign({},state.misprivx.forms,susformas)
        },
        setPropToFocusedInsti:(state,payload)=>{
            if(!state.focusedInstitucion[payload.what]){
                let tosave={};
                tosave[payload.what]=payload.to;
                state.focusedInstitucion=Object.assign({},state.focusedInstitucion,tosave);
              }
            else{
                state.focusedInstitucion[payload.what]=payload.to;
            }
            //y forzar update en su papa
            state.misprivx.inst[state.focusedInstitucion.id]= Object.assign({},state.misprivx.inst[state.focusedInstitucion.id],state.focusedInstitucion);
        },
        pushToArray: (state,payload) => {
            state[payload.array].push(payload.value);
        },
        removeFromArray: (state,payload)=>{
            let indiexist = state[payload.array].findIndex(unitem => unitem[payload.attr]==payload.value);
            if(indiexist!=-1){
                state[payload.array].splice(indiexist,1);
            }
        },
        updateMyAvatar: (state,avatarURL)=>{
            state.profile_userInfo.photoURL=avatarURL;
        },
        //Editar array
        editArray(state, payload){
            const index = state[payload.array].findIndex((item) => item[payload.attr] === payload.value);
            if(index !== -1){
                state[payload.array].splice(index, 1, payload.updatedValue);
            }
        },
        //eliminar institución sólo front
        removeFromArrayByReference: (state, payload) => {
            let index = state.allInstitutions.indexOf(payload);
            if(index != -1){
                state.allInstitutions.splice(index, 1);
            }
        }
    },
    actions:{

    }
})