<template>
  <f7-page class="grisback soyformaone" name="formaone">
    <f7-navbar :title="formaName" back-link="Back">
      <f7-nav-right>
        <f7-link icon-material="email" @click="requestMailSend()"></f7-link>
        <f7-link icon-material="print" @click="printme()"></f7-link>
        </f7-nav-right>
    </f7-navbar>
    <f7-block>
    <f7-block-title>INFORMACIÓN DEL PACIENTE</f7-block-title>
          <p>Datos ingresados por el paciente. Ud puede modificarlos si lo considera necesario.</p>

  <f7-row>
    <f7-col width="100" medium="50">
      <f7-list class="preprint no-margin-top no-margin-bottom">
         <f7-list-input
        label="RUT"
        type="text"
        placeholder="RUT o Pasaporte"
        :value="focusedForm.rutid"
        @input="focusedForm.rutid = $event.target.value"
        @blur="grabarEsto('rutid')"
      ></f7-list-input>
      <f7-list-input
        label="Nombres"
        type="text"
        placeholder="Nombres"
        :value="focusedForm.nombres"
        @input="focusedForm.nombres = $event.target.value"
        @blur="grabarEsto('nombres')"
      ></f7-list-input>
      <f7-list-input
        label="Apellido Paterno"
        type="text"
        placeholder="Apellido Paterno"
        :value="focusedForm.apepat"
        @input="focusedForm.apepat = $event.target.value"
        @blur="grabarEsto('apepat')"
      ></f7-list-input>
      <f7-list-input
        label="Apellido Materno"
        type="text"
        placeholder="Apellido Materno"
        :value="focusedForm.apemat"
        @input="focusedForm.apemat = $event.target.value"
        @blur="grabarEsto('apemat')"
      ></f7-list-input>
       <f7-list-input
          label="Sexo"
          type="select"
          :value="focusedForm.sexo"
          @input="focusedForm.sexo = $event.target.value"
          @change="grabarEsto('sexo')"
          placeholder="Seleccione..."
        >
        <option value="">Seleccione</option>
          <option value="Masculino">Masculino</option>
          <option value="Femenino">Femenino</option>
        </f7-list-input>
        <li><fechcomp name="Fecha de Nacimiento" propiedad="fechanac"></fechcomp></li>
        <f7-list-input
        label="Edad"
        class="clpreadonly"
        type="text"
        placeholder="Edad"
        :value="suageparts(focusedForm.fechanac)"
       readonly
      ></f7-list-input>
      </f7-list>
    </f7-col>
    <f7-col width="100" medium="50">
 <f7-list class="preprint no-margin-top no-margin-bottom">
         <f7-list-input
        label="Domicilio"
        type="text"
        placeholder="Domicilio"
        :value="focusedForm.domicilio"
        @input="focusedForm.domicilio = $event.target.value"
        @blur="grabarEsto('domicilio')"
      ></f7-list-input>
      <f7-list-input
        label="Región"
        type="text"
        placeholder="Región"
        :value="focusedForm.region"
       readonly
      ></f7-list-input>
      <f7-list-input
        label="Ciudad"
        type="text"
        placeholder="Ciudad"
        :value="focusedForm.provincia"
        readonly
      ></f7-list-input>
      <f7-list-input
        label="Comuna"
        type="text"
        placeholder="Comuna"
        :value="focusedForm.comuna"
       readonly
      ></f7-list-input>
      <f7-list-input
        label="Telefono"
        type="text"
        placeholder="Telefono"
        :value="focusedForm.fono"
        readonly
      ></f7-list-input>
       <f7-list-input
        label="Previsión"
        type="text"
        placeholder="Previsión"
        :value="focusedForm.prevision"
        readonly
      ></f7-list-input>
 </f7-list>
    </f7-col>
  </f7-row>

    </f7-block>

  <f7-block-title>DATOS DE LA PROCEDENCIA</f7-block-title>
    <f7-block>
      <f7-row>
    <f7-col width="100" medium="50">
      <f7-list class="no-margin">
        <f7-list-input
    label="Seleccione institución para cargar datos"
    type="select"
     :value="prefferedInsti"
    @input="focusedForm.prfi = $event.target.value"
    @change="grabarEsto('prfi')"
  >
    <option v-for="unainsti in myInstitx" :key="unainsti.id" :value="unainsti.id">{{unainsti.institucion}}</option>
  </f7-list-input>
      </f7-list>
    </f7-col>
      </f7-row>
  <f7-row class="clpreadonly">
    <f7-col width="100" medium="50">
      <f7-list class="preprint no-margin-top no-margin-bottom">
         <f7-list-input
        label="Profesional Responsable"
        type="text"
        placeholder="Profesional Responsable"
        :value="myName"
        readonly
      ></f7-list-input>
      <f7-list-input
        label="Región"
        type="text"
        placeholder="Región"
        :value="myOrigen.region"
        readonly
      ></f7-list-input>
      <f7-list-input
        label="Provincia"
        type="text"
        placeholder="Provincia"
        :value="myOrigen.provincia"
        readonly
      ></f7-list-input>
      <f7-list-input
        label="Comuna"
        type="text"
        placeholder="Comuna"
        :value="myOrigen.comuna"
        readonly
      ></f7-list-input>
       <f7-list-input
        label="Dirección"
        type="text"
        placeholder="Dirección"
        :value="myOrigen.direccion"
        readonly
      ></f7-list-input>

      </f7-list>
    </f7-col>
    <f7-col width="100" medium="50">
      <f7-list class="preprint no-margin-top no-margin-bottom">
         <f7-list-input
        label="Institución"
        type="text"
        placeholder="Institución"
        :value="myOrigen.institucion"
        readonly
      ></f7-list-input>
       <f7-list-input
        label="Unidad"
        type="text"
        placeholder="Unidad"
        :value="myOrigen.unidad"
        readonly
      ></f7-list-input>
      <f7-list-input
        label="Correo electrónico"
        type="text"
        placeholder="Correo electrónico"
        :value="profile_userInfo.email"
        readonly
      ></f7-list-input>
      <f7-list-input
        label="Telefono"
        type="text"
        placeholder="Telefono"
        :value="myOrigen.fono"
        readonly
      ></f7-list-input>
      <f7-list-input
        label="Fax"
        type="text"
        placeholder="Fax"
        :value="myOrigen.fax"
        readonly
      ></f7-list-input>
      </f7-list>
    </f7-col>
  </f7-row>
    </f7-block>

<f7-block-title>ANTECEDENTES DE LA MUESTRA</f7-block-title>
    <f7-block>
  <f7-row>
    <f7-col>
      <f7-list class="preprint no-margin-top">
        <li><fechcomp name="Fecha y Hora de Obtención" propiedad="fechaobtencion" :timePicker="true"></fechcomp></li>
      </f7-list>
    </f7-col>
  </f7-row>
    </f7-block>
    <f7-block-title>Virus detectado localmente</f7-block-title>
<f7-block>
  <f7-list class="no-margin alrededor">
    <f7-list-item divider title="Inmuno Fluorescencia"></f7-list-item>
    <f7-list-item
    v-for="inmuno in inmunofluoresencia" :key="inmuno.id"
     checkbox 
     :title="inmuno.nam" 
     :value="inmuno.id" 
     :name="inmuno.nam" 
     :checked=" (focusedForm.inmunofluoresencia && focusedForm.inmunofluoresencia.includes(inmuno.id))"
      @change="checkonebox($event.target,'inmunofluoresencia')"></f7-list-item>
    <f7-list-input
    label="Establecimiento Inmuno Fluorescencia"
    type="text"
    placeholder="Establecimiento Inmuno Fluorescencia"
       :value="focusedForm.inmunofluoresencia_establecimiento"
        @input="focusedForm.inmunofluoresencia_establecimiento = $event.target.value"
        @blur="grabarEsto('inmunofluoresencia_establecimiento')"
    clear-button
  >
  </f7-list-input>
  </f7-list>
</f7-block>

<f7-block>
  <f7-list class="no-margin alrededor">
    <f7-list-item divider title="Test Pack"></f7-list-item>
 
    <f7-list-item
    v-for="untestpacl in testpack" :key="untestpacl.id"
     checkbox 
     :title="untestpacl.nam" 
     :value="untestpacl.id" 
     :name="untestpacl.nam" 
     :checked=" (focusedForm.testpack && focusedForm.testpack.includes(untestpacl.id))"
      @change="checkonebox($event.target,'testpack')"></f7-list-item>
      
    <f7-list-input
    label="Establecimiento Testpack"
    type="text"
    placeholder="Establecimiento Testpack"
       :value="focusedForm.testpack_establecimiento"
        @input="focusedForm.testpack_establecimiento = $event.target.value"
        @blur="grabarEsto('testpack_establecimiento')"
    clear-button
  >
  </f7-list-input>
  </f7-list>
</f7-block>




<f7-block>
  <f7-list class="no-margin alrededor">
    <f7-list-item divider title="RT-PCR / Film Array"></f7-list-item>
    <f7-list-item radio value="RT_PCR" name="rtpcrfilmarray_method" :checked="focusedForm.rtpcrfilmarray_method=='RT_PCR'" title="RT-PCR"  @change="checkoneradio($event.target,'rtpcrfilmarray_method')"></f7-list-item>
     <f7-list-item radio value="Film_Array" name="rtpcrfilmarray_method" :checked="focusedForm.rtpcrfilmarray_method=='Film_Array'" title="Film Array" @change="checkoneradio($event.target,'rtpcrfilmarray_method')"></f7-list-item>

    <f7-list-item
    v-for="untestpacl in rtpcrfilmarray" :key="untestpacl.id"
     checkbox 
     :title="untestpacl.nam" 
     :value="untestpacl.id" 
     :name="untestpacl.nam" 
     :checked=" (focusedForm.rtpcrfilmarray && focusedForm.rtpcrfilmarray.includes(untestpacl.id))"
      @change="checkonebox($event.target,'rtpcrfilmarray')"></f7-list-item>
       <f7-list-input
    label="Otro"
    type="text"
    placeholder="Otro"
       :value="focusedForm.rtpcrfilmarray_otro"
        @input="focusedForm.rtpcrfilmarray_otro = $event.target.value"
        @blur="grabarEsto('rtpcrfilmarray_otro')"
    clear-button
  >
  </f7-list-input>
    <f7-list-input
    label="Establecimiento RT-PCR / Film Array"
    type="text"
    placeholder="Establecimiento RT-PCR / Film Array"
       :value="focusedForm.rtpcrfilmarray_establecimiento"
        @input="focusedForm.rtpcrfilmarray_establecimiento = $event.target.value"
        @blur="grabarEsto('rtpcrfilmarray_establecimiento')"
    clear-button
  >
  </f7-list-input>
  </f7-list>
</f7-block>



<f7-block>
  <f7-list class="no-margin alrededor">
    <f7-list-item divider title="Tipo de Muestra"></f7-list-item>
 
    <f7-list-item
    v-for="untestpacl in tipomuestraf1" :key="untestpacl.id"
     checkbox 
     :title="untestpacl.nam" 
     :value="untestpacl.id" 
     :name="untestpacl.nam" 
     :checked=" (focusedForm.tipomuestraf1 && focusedForm.tipomuestraf1.includes(untestpacl.id))"
      @change="checkonebox($event.target,'tipomuestraf1')"></f7-list-item>
      
    <f7-list-input
    label="Otro tipo de muestra"
    type="text"
    placeholder="Otro tipo de muestra"
       :value="focusedForm.tipomuestraf1_otro"
        @input="focusedForm.tipomuestraf1_otro = $event.target.value"
        @blur="grabarEsto('tipomuestraf1_otro')"
    clear-button
  >
  </f7-list-input>
  </f7-list>
</f7-block>


<f7-block-title>ANTECEDENTES CLÍNICOS/EPIDEMIOLÓGICOS</f7-block-title>
    <f7-block>
  <f7-row>
    <f7-col width="100" medium="50">
      <f7-list class="preprint no-margin-top no-margin-bottom fgrislist">
        <li><fechcomp name="Fecha de Inicio de Síntomas" propiedad="fechainisint"></fechcomp></li>
        <f7-list-item title="Trabajor avícola/Cerdos"><f7-icon :material="focusedForm.trabajador_avicolaocerdo?'check':'close'" slot="after"></f7-icon></f7-list-item>
        <f7-list-item title="Embarazo" :after="focusedForm.semgestacion?(focusedForm.semgestacion+' sem'):null"><f7-icon :material="focusedForm.embarazo?'check':'close'" slot="after"></f7-icon></f7-list-item>
      </f7-list>
    </f7-col>
    <f7-col width="100" medium="50">
      <f7-list class="preprint no-margin-top no-margin-bottom fgrislist">
        <li><fechcomp name="Fecha Primera Consulta" propiedad="fechaprimconsult"></fechcomp></li>
        <f7-list-item title="Viajó extranjero <14 dias"><f7-icon :material="focusedForm.viajex?'check':'close'" slot="after"></f7-icon></f7-list-item>
       <f7-list-item v-for="(ciudadpais,idx) in focusedForm.ciudadesPaises" :key="idx" :footer="ciudadpais.fechas.join(' al ')" :title="ciudadpais.pais" :after="ciudadpais.ciudad" ></f7-list-item>

      </f7-list>

    </f7-col>
  </f7-row>
    </f7-block>

<f7-block-title>SÍNTOMAS</f7-block-title>
<f7-block>
  <f7-list class="no-margin alrededor">
    <f7-list-item
    v-for="untestpacl in sintomasf1" :key="untestpacl.id"
     checkbox 
     :title="untestpacl.nam" 
     :value="untestpacl.id" 
     :name="untestpacl.nam" 
     :checked=" (focusedForm.sintomasf1 && focusedForm.sintomasf1.includes(untestpacl.id))"
      @change="checkonebox($event.target,'sintomasf1')"></f7-list-item>
      
    <f7-list-input
    v-show="(focusedForm.sintomasf1 && focusedForm.sintomasf1.includes('enfermbase'))"
    label="Especifique enfermedad de base"
    type="text"
    placeholder="Especifique enfermedad de base"
       :value="focusedForm.enfermedad_de_base"
        @input="focusedForm.enfermedad_de_base = $event.target.value"
        @blur="grabarEsto('enfermedad_de_base')"
    clear-button
  >
  </f7-list-input>
  </f7-list>
</f7-block>

<f7-block-title>Antecedente Vacunación Influenza</f7-block-title>
<f7-block>
  <f7-list class="no-margin alrededor">
  <f7-list-item>
    <span>Vacuna contra influenza</span>
    <f7-toggle
      @change="toglethis($event,'vacunainfluenza')"
      :checked="focusedForm.vacunainfluenza"
      class="toggle"
    ></f7-toggle>
        </f7-list-item>
<li v-show="focusedForm.vacunainfluenza" ><fechcomp name="Fecha Vacunación Influenza" propiedad="vacunainfluenza_date"></fechcomp></li>

        
  </f7-list>
</f7-block>


<f7-block-title>Hospitalización</f7-block-title>
<f7-block>
  <f7-list class="no-margin alrededor">
  <f7-list-item>
    <span>Hospitalizado</span>
    <f7-toggle
      @change="toglethis($event,'hospitliazdo')"
      :checked="focusedForm.hospitliazdo"
      class="toggle"
    ></f7-toggle>
        </f7-list-item>
 
  <template v-if="focusedForm.hospitliazdo" >
    <li ><fechcomp name="Fecha Hospitalización" propiedad="fecha_hospitalizacion"></fechcomp></li>
 <f7-list-input
    label="Diagnóstico de ingreso"
    type="text"
    placeholder="Diagnóstico de ingreso"
       :value="focusedForm.diagnoingrhosp"
        @input="focusedForm.diagnoingrhosp = $event.target.value"
        @blur="grabarEsto('diagnoingrhosp')"
    clear-button
  >
  </f7-list-input>
   <f7-list-item
    v-for="untestpacl in hospitalixs" :key="untestpacl.id"
     checkbox 
     :title="untestpacl.nam" 
     :value="untestpacl.id" 
     :name="untestpacl.nam" 
     :checked=" (focusedForm.hospitalixs && focusedForm.hospitalixs.includes(untestpacl.id))"
      @change="checkonebox($event.target,'hospitalixs')"></f7-list-item>

      <f7-list-item divider title="Terapia Antiviral"></f7-list-item>
      <f7-list-item>
    <span>Uso Antiviral</span>
    <f7-toggle
      @change="toglethis($event,'antiviral_uso')"
      :checked="focusedForm.antiviral_uso"
      class="toggle"
    ></f7-toggle>
        </f7-list-item>
        
    <template v-if="focusedForm.antiviral_uso">
     <li ><fechcomp name="Fecha Inicio Tratamiento" propiedad="antiviral_fechainiciotto"></fechcomp></li>
       <f7-list-item
    v-for="untestpacl in antiviral_cual" :key="untestpacl.id"
     checkbox 
     :title="untestpacl.nam" 
     :value="untestpacl.id" 
     :name="untestpacl.nam" 
     :checked=" (focusedForm.antiviral_cual && focusedForm.antiviral_cual.includes(untestpacl.id))"
      @change="checkonebox($event.target,'antiviral_cual')"></f7-list-item>
    </template>
    </template>
  </f7-list>
</f7-block>


<f7-block-title>Fallecimiento</f7-block-title>
<f7-block>
  <f7-list class="no-margin alrededor">
  <f7-list-item>
    <span>Fallece</span>
    <f7-toggle
      @change="toglethis($event,'fallece')"
      :checked="focusedForm.fallece"
      class="toggle"
    ></f7-toggle>
        </f7-list-item>
        <template v-if="focusedForm.fallece">
          <li><fechcomp name="Fecha Fallecimiento" propiedad="fallece_fecha"></fechcomp></li>
 <f7-list-input
    label="Diagnóstico fallecimiento"
    type="text"
    placeholder="Diagnóstico fallecimientoo"
       :value="focusedForm.fallece_diagno"
        @input="focusedForm.fallece_diagno = $event.target.value"
        @blur="grabarEsto('fallece_diagno')"
    clear-button
  >
  </f7-list-input>
        </template>

        
  </f7-list>
</f7-block>


<f7-block>
  <f7-button fill raised @click="firmarycerrar('f1')" >Firmar y Cerrar</f7-button>
  <f7-button class="margin-top" fill raised @click="printme()" >Firmar e Imprimir</f7-button>

</f7-block>

  </f7-page>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import {formasmixin} from '../components/formasmixin';
import {paxhelpersmixin} from '../components/paxhelpersmixin';

import fechcomp from '../components/fechcomp.vue';

export default {
     mixins: [formasmixin,paxhelpersmixin],
     components:{fechcomp},

  data(){
    return{
      formaName: 'Notificación inmediata y envío de muestras a confirmación IRA grave y 2019-nCoV',
    inmunofluoresencia:[{
      nam:'Influenza A',
      id: 'influenza_A'
    },
    {
      nam:'Influenza B',
      id: 'influenza_B'
    },
    {
      nam:'VRS',
      id: 'VRS'
    },
    {
      nam:'Adenovirus',
      id: 'Adenovirus'
    },
    {
      nam:'Parainfluenza',
      id: 'Parainfluenza'
    },
    {
      nam:'Metapneumovirus',
      id: 'Metapneumovirus'
    },
    {
      nam:'Negativo',
      id: 'Negativo'
    }
    ],
     testpack:[{
      nam:'Influenza A',
      id: 'influenza_A'
    },
    {
      nam:'Influenza B',
      id: 'influenza_B'
    },
    {
      nam:'Negativo',
      id: 'Negativo'
    }
    ],
    rtpcrfilmarray:[
      {
      nam:'Influenza A (H1N1) pdm',
      id: 'influenza_A_H1N1'
    },
    {
      nam:'Influenza A (H3N2)',
      id: 'influenza_A_H3N2'
    },
    {
      nam:'Influenza A no subtipificable',
      id: 'influenza_A_nosubtipfc'
    },
     {
      nam:'Influenza B',
      id: 'influenza_B'
    },
    {
      nam:'Negativo',
      id: 'Negativo'
    }
    ],
    tipomuestraf1:[{
      nam:'Lavado Broncoalveolar',
      id: 'lavado_broncoalveolar'
    },
    {
      nam:'Esputo',
      id: 'esputo'
    },
    {
      nam:'Aspirado Traqueal',
      id: 'aspirado_traqueal'
    },
    {
      nam:'Aspirado Nasofaríngeo',
      id: 'aspirado_nasofaringeo'
    },
    {
      nam:'Tórulas Nasofaríngeas',
      id: 'torulas_nasofaringeas'
    },
    {
      nam:'Biopsia o Tejido Pulmonar',
      id: 'bioptejpulmon'
    }],
    sintomasf1:[
      {nam:'Fiebre sobre 38 C',
      id: 'fiebresobretroch'},
      {nam:'Dolor de garganta',
      id: 'dolorgargnt'},
      {nam:'Mialgia',
      id: 'mialgia'},
      {nam:'Neumonia',
      id: 'neuomni'},
      {nam:'Encefalitis',
      id: 'encefaltis'},
      {nam:'Tos',
      id: 'tos'},
      {nam:'Rinorrea/congestión Nasal',
      id: 'rinorrea'},
      {nam:'Dificultad Respiratoria',
      id: 'difresp'},
      {nam:'Hipotensión',
      id: 'hipotens'},
      {nam:'Cefalea',
      id: 'cefalea'},
      {nam:'Taquipnea',
      id: 'taquipnea'},
      {nam:'Hipoxia',
      id: 'hipoxia'},
      {nam:'Cianosis',
      id: 'cianosis'},
      {nam:'Deshidratación o rechazo alimentario (lactantes)',
      id: 'deshidratacion'},
      {nam:'Compromiso hemodinámica',
      id: 'comphemodinam'},
      {nam:'Consulta Repetida por cuadro respiratorio',
      id: 'consulrepetidresp'},
      {nam:'Enfermedad de Base',
      id: 'enfermbase'},
    ],
     hospitalixs:[
      {nam:'Grave',
      id: 'grave'},
      {nam:'VM',
      id: 'vm'},
      {nam:'ECMO',
      id: 'ecmo'},
      {nam:'Ingreso UCI',
      id: 'uci'},
      {nam:'VAFO',
      id: 'vafo'},
     ],
       antiviral_cual:[
      {nam:'Oseltamivir',
      id: 'oseltamivir'},
      {nam:'Zanamivir',
      id: 'zanamivir'}
     ]
    }
  },
    computed:{
		  ...mapState(['focusedForm','profile_userInfo','misprivx']),
      ...mapGetters(['myName','myInstitx']),
       prefferedInsti(){
         return  this.focusedForm.prfi || Object.keys(this.myInstitx)[0];
       },
       myOrigen(){
         return this.myInstitx[this.prefferedInsti] || {};
       }

  },
  mounted(){
    console.log('focusedForm',this.focusedForm);
  },
    beforeDestroy(){
        this.$store.commit('setFormtoHolder',{what:this.focusedForm.fuid,to:this.focusedForm});
  },
  methods:{
    printme(sendmail=false){
      let ptopromes=[];
        let firma = {
          who: this.profile_userInfo.uid,
          whon: this.profile_userInfo.displayName,
          em: this.profile_userInfo.email,
          cuan: Date.now()
        };
       if(!this.focusedForm.f1){
         if(!sendmail){
          this.$f7.dialog.preloader('Firmando Generando PDF...');
         }
         ptopromes.push(this.$firebase.database().ref('forms/'+this.focusedForm.fuid+'/f1').set(firma));
       }
       else{
         ptopromes.push(true);
          if(!sendmail){
            this.$f7.dialog.preloader('Generando PDF...');
          }
       }
      return Promise.all(ptopromes).then(multisnasp=>{
         if(!this.focusedForm.f1){
            this.focusedForm.f1=firma;
            this.$store.commit('setFormtoHolder',{what:this.focusedForm.fuid,to:this.focusedForm});
         }
         this.$firebase.analytics().logEvent('imprimiendo_f1');
        return this.$firebase.auth().currentUser.getIdToken();
      }).then((token)=> {
          let req = new XMLHttpRequest();
          req.onload = (e)=> {
              this.$f7.dialog.close();
              if (e.target.status == 200) {
                if(sendmail){
                  console.log('ok');
                  this.$f7.dialog.alert('Email enviado');
                }
                else{
                  // Create a new Blob object using the 
                  //response data of the onload object
                  var blob = new Blob([e.target.response], {type: 'application/pdf'});
                  //Create a link element, hide it, direct 
                  //it towards the blob, and then 'click' it programatically
                  let a = document.createElement("a");
                  a.style = "display: none";
                  document.body.appendChild(a);
                  //Create a DOMString representing the blob 
                  //and point the link element towards it
                  let url = window.URL.createObjectURL(blob);
                  a.href = url;
                  a.classList.add("external");
                  a.download = 'isp.pdf';
                  //programatically click the link to trigger the download
                  a.click();
                  //release the reference to the file by revoking the Object URL
                  window.URL.revokeObjectURL(url);
                }
              }else{
                  //deal with your error state here
                   this.$f7.dialog.close();
                   console.log('Error al descargar',e);
              }
          };
          req.onerror = ()=> {
              this.$f7.dialog.close();
            console.log('Error al request');
          };
          if(sendmail){
            req.open('GET', this.$apiurl+'mailer/'+this.focusedForm.fuid+'/'+sendmail, true);
          }
          else{
            req.open('GET', this.$apiurl+'printer/'+this.focusedForm.fuid, true);
          }
          req.setRequestHeader('Authorization', 'Bearer ' + token);
          req.responseType = 'blob';
          req.send();
      }).catch(error=>{
        this.$f7.dialog.close();
        this.$f7.dialog.alert(error.message || error, error.code);
      });
    }
  }
}
</script>
<style>
.preprint .item-inner{
  padding-top: 2px;
    padding-bottom: 2px;
}


.soyformaone .item-content.item-input {
background: #f1f1f1;
}

.soyformaone  .item-input-wrap{
  background:#FFF;
  border-radius: 7px;
}
.soyformaone  .item-input-wrap input, .soyformaone  .item-input-wrap select{
  padding: 3px;
}
.soyformaone .clpreadonly  .item-input-wrap input:read-only{
  background: #f1f1f1!important;
}


.alrededor{
  padding: 4px;
    border: 1px solid #0279ff;
    border-radius: 10px;
}
.fgrislist li{
      background: #f1f1f1;
}
</style>