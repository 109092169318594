<template>
   <f7-block>
   <f7-block-title>Política de Privacidad y Protección de Datos.</f7-block-title>
El conocimiento y aceptación de la siguiente Política de Privacidad y Protección de Datos es esencial para el uso de este sitio web y su contenido, por lo que la lectura de las disposiciones que a continuación se indican es obligatoria para todos los usuarios de este sitio web.
<br/><b>Importante para menores de edad: </b>En caso que seas menor de 18 años, ten presente que es necesario que tu Representante Legal autorice que podamos realizar el tratamiento de tus datos personales. Al inscribirte en nuestro sitio HICAPPS SpA entiende que:
<ul>
<li>Los Representantes Legales de los usuarios menores de 18 años han otorgado su consentimiento expreso para que HICAPPS SpA pueda realizar tratamiento de sus datos personales en conformidad a la presente Política.</li>
<li>Los Representantes Legales de los usuarios menores de 18 años, antes de dar el consentimiento anterior, han leído y entendido íntegramente nuestra Política de Privacidad y Protección de Datos Personales.</li>
</ul>
En caso que tu Representante Legal no entregue la autorización referida anteriormente, te solicitamos que no te inscribas en nuestro Sitio Web. 

 <f7-block-title>1. General</f7-block-title>

<p>www.coronachile.com es un sitio web que tiene como objetivo hacer más eficientes los tiempos de atención de los pacientes con sospecha de CoVid19. Este objetivo se logra por medio de un procedimiento en que los usuarios que son pacientes con sospecha de CoVid19, completan voluntariamente el formulario electrónico entregado por el Sitio con sus datos personales, para que, posteriormente, el profesional de la salud a cargo de su atención acceda a dicha información por medio del número de formulario. Es decir, el objetivo del Sitio es únicamente recolectar la información funcionando como un intermediario entre el paciente y el profesional de la salud. Esto permite que se facilite la tarea de recolección de información para la generación de los formularios obligatorios requeridos por el ISP/MINSAL. 

</p><p>Los datos personales de los usuarios recibidos y almacenados por HICAPPS SpA o que son ingresados por ellos en www.coronachile.com (en adelante el “Sitio”) son confidenciales y serán usados en conformidad a las disposiciones legales sobre el tema. HICAPPS SpA, declara que cumple la normativa vigente respecto a la protección de datos, en particular lo dispuesto en el artículo 19 Nº 4 de la Constitución Política de la República, y la Ley 19.628, Sobre Protección de la Vida Privada.

</p><p>La presente Política de Privacidad y Protección de Datos se aplica exclusivamente a la información entregada y recibida por los usuarios del Sitio, y no a aquella ofrecida a otras o por compañías u organizaciones o Sitios Web con los que el Sitio contenga enlaces y sobre los que HICAPPS SpA no tiene su control. HICAPPS SpA no se responsabiliza por el actuar de los sitios web a los cuales se pueda acceder a través de www.coronachile.com. 

</p><p>Sírvase leer cuidadosamente nuestra Política de Privacidad y Protección de Datos, para que usted pueda entender cómo obtenemos, utilizamos y protegemos los datos personales que se nos proporciona. El usuario que tras la lectura de esta Política de Privacidad y Protección de Datos continúe utilizando el Sitio web, estará manifestando su aceptación expresa de la misma y el tratamiento de sus datos personales para las finalidades indicadas en la presente Política de Privacidad y Protección de Datos. En caso contrario, el usuario que no esté de acuerdo con lo establecido en esta política deberá abandonar el Sitio y abstenerse de su uso.
</p>
<f7-block-title>2. Definiciones:</f7-block-title>
<ol type="a">
<li>	HICAPPS: se refiere a la Sociedad HICAPPS SpA.
</li><li>	Almacenamiento de datos: operación de conservación o custodia de datos en un registro o banco de datos.
</li><li>	Comunicación o transmisión de datos: operación de dar a conocer de cualquier forma los datos de carácter personal a personas distintas del titular, sean determinadas o indeterminadas.
</li><li>	Tratamiento de datos: cualquier operación o complejo de operaciones o procedimientos técnicos, de carácter automatizado o no, que permitan recolectar, almacenar, grabar, organizar, elaborar, seleccionar, extraer, confrontar, interconectar, disociar, comunicar, ceder, transferir, transmitir o cancelar datos de carácter personal, o utilizarlos en cualquier otra forma. 
</li><li>	Datos de carácter personal o datos personales: los relativos a cualquier información concerniente a personas naturales, identificadas o identificables.
</li><li>	Datos sensibles: aquellos datos personales que se refieren a las características físicas o morales de las personas o a hechos o circunstancias de su vida privada o intimidad, tales como los hábitos personales, el origen racial, las ideologías y opiniones políticas, las creencias o convicciones religiosas, los estados de salud físicos o psíquicos y la vida sexual.
</li><li>	Titular de los datos: la persona natural a la que se refieren los datos de carácter personal y quien autoriza el almacenamiento, comunicación o transmisión y tratamiento de datos.
</li><li>	EPIVIGILIA: Soporte informático para notificar a la Autoridad Sanitaria de la Enfermedades Transmisibles de Declaración Obligatoria, en conformidad con el Dto. 158 2004 del Ministerio de Salud, y las instrucciones enviadas en el Ordinario B51, Nº276, denominado “Actualización de alerta y refuerzo de vigilancia epidemiológica ante brote de CoVid-19”. 
</li></ol>
<p>Las definiciones señaladas en las letras b) a la g) anteriores se encuentran contenidas en la Ley N° 19.628, que Regula la Protección de Datos de Carácter Personal.
</p>
<f7-block-title>3. Uso y tratamiento de los datos personales de los usuarios.</f7-block-title>

<p>HICAPPS registra, utiliza y protege toda la información personal reunida sobre los Usuarios, de conformidad con lo dispuesto por la legislación actualmente vigente sobre protección de datos y esta Política de Privacidad y Protección de Datos. 
</p>
<p>HICAPPS recolecta los datos entregados por los Usuarios Paciente, que completaron en el Formulario Paciente, y les hace entrega de dichos datos al Profesional de la Salud correspondiente. Esta recolección y entrega funciona  de la siguiente manera: 
</p>
<ol type="a">
  <li>	Aquellos usuarios que ingresan a través de la pestaña “Soy un Paciente”, (en adelante “Usuario Paciente”): El Usuario que ingresa a través de esta pestaña podrá completar el formulario contenido en el Sitio, denominado “Formulario Paciente”, con sus datos personales, con el fin de que el Sitio recopile dicha información y haga entrega de los datos contenidos en él al Profesional de la Salud que corresponda. Dicho Profesional de la Salud, será aquel que acceda al Sitio con el número identificador del Formulario Paciente, que será entregado por el mismo Usuario Paciente. 

Previo a esto, el usuario deberá dar su consentimiento expreso e inequívoco en la ventana pop-up que se desplegará y que contendrá la presente Política de Privacidad y Protección de Datos. Dicho consentimiento dice relación con el conocimiento de la presente Política y sus implicancias, como asimismo, que el Usuario hace entrega de manera voluntaria sus datos para los fines que estima esta Política. Se entiende que el Usuario ha dado su consentimiento, al hacer click en el botón 'Continuar' del modal. De no aceptar, el Usuario no podrá continuar con el flujo de uso de la aplicación. 

La veracidad e integridad de los datos ingresados en el Formulario Paciente es responsabilidad única del Usuario que completa las casillas. Asimismo, el cuidado de su número de Formulario y a las personas que les hace entrega de dicho folio es de su exclusiva responsabilidad, no cabiendo responsabilidad alguna por parte de HICAPPS en el manejo de dicha información. 

La información contenida en el Sitio en ningún caso pretende reemplazar la guía de su médico o del profesional de la salud que corresponda. Si Usted desea obtener un diagnóstico, pronóstico o tratamiento, debe siempre consultar a un médico o al profesional de la salud que corresponda. La información que usted obtenga del Sitio no substituye de ninguna manera la relación con un profesional de la salud, ya que mediante la información que se entrega no se está practicando la medicina, ni se diagnostica, pronostica o aconseja un determinado tratamiento médico o el consumo de un medicamento determinado.
</li>
<li>	Aquellos usuarios que ingresan a través de la pestaña “Soy un Profesional de la Salud”, (en adelante “Usuario Profesional Salud”): Los datos personales son recolectados únicamente para que el usuario pueda hacer uso de la plataforma del Sitio. Para poder continuar en el Sitio y acceder a la información del Usuario Paciente deberá hacer click en el botón 'Continuar' del modal. De no aceptar, el Usuario no podrá continuar con el flujo de uso de la aplicación.

Por medio del código de formulario, entregado personalmente por el Usuario Paciente, podrá acceder a los datos entregados voluntariamente por dicho Usuario. HICAPPS no se responsabiliza por la veracidad de la información contenida en dicho formulario, siendo responsabilidad única y exclusiva del Usuario Profesional Salud corroborar que dicha información sea verídica y corresponda a la persona que está atendiendo. El Usuario Profesional Salud se obliga a proteger los datos que reciben y a utilizarlos únicamente para la generación de los formularios requeridos por la autoridad sanitaria (ISP/MINSAL). Asimismo, el Profesional de la Salud debe guardar estricta reserva al respecto, ya que los datos contenidos en el Formulario Paciente, forman parte del Secreto Profesional, con las implicancias normativas que conlleva su contravención. 

Asimismo, el Sitio no es un reemplazo de EPIVIGILIA, por lo que el Usuario Profesional Salud deberá completar, imprimir y enviar los documentos de la autoridad sanitaria por los canales oficiales existentes. 

HICAPPS deja expresa constancia que no son parte del formulario la sintomatología del usuario paciente, siendo de exclusiva responsabilidad del Usuario Profesional Salud su control, verificación y tratamiento.

Al aceptar la presente Política de Privacidad y Protección de Datos, asimismo autoriza a HICAPPS a comunicar a terceros información estadística elaborada a partir de los datos personales de sus usuarios (tanto los Usuario Paciente como Usuario Profesional Salud), cuando de dichos datos no sea posible identificar individualmente a los titulares (datos de-identificados), de conformidad a la Ley.
</li></ol>

<f7-block-title>4. Conservación de los datos.</f7-block-title>

<p>Los datos personales del usuario serán mantenidos por HICAPPS mientras el Usuario no solicite la supresión de los mismos y conservados durante los plazos legales para el cumplimiento de obligaciones y atención de posibles responsabilidades.
</p>
<f7-block-title>5. Consentimiento.</f7-block-title>

<p>Al utilizar el Sitio, usted ya sea en calidad de Usuario Paciente, Usuario Profesional Salud y/o visitante, manifiesta su aceptación a los términos de esta Política de Privacidad y Protección de Datos. Además dando cumplimiento a lo exigido por la normativa vigente respecto a la protección de datos, en particular la Ley 19.628, Sobre Protección de la Vida Privada, y atendiendo a lo establecido en nuestra Política de Privacidad y de Protección de Datos, previo a que el Usuario envíe sus datos personales deberá dar su consentimiento expreso e inequívoco haciendo click en el botón 'continuar' del modal. En caso de que nos escriba a través de nuestro correo electrónico de contacto (info@coronachile.com), estará aceptando expresamente lo establecido en nuestra Política de Privacidad y de Protección de Datos y nos autoriza a recabar sus datos para la finalidad o petición que nos haya realizado.

</p><p>El Usuario garantiza que los datos personales facilitados a HICAPPS son veraces y se hace responsable de comunicar a aquél cualquier modificación de los mismos. También se hace responsable de los datos que indique respecto de los nombres y/o correos electrónicos de los destinatarios; no siendo responsable HICAPPS por la no recepción de los mismos si existiera algún error en la indicación de los mismo o por cualquier motivo atribuible al Usuario emisor.

</p><p>HICAPPS se compromete a proteger la información confidencial a la que tenga acceso. 

</p><p>Si no está de acuerdo con los términos de nuestra Política de Privacidad y Protección de Datos, le pedimos que no nos proporcione información personal alguna, abandone este Sitio y no haga uso del mismo.
</p>
<f7-block-title>6. Estadísticas de Navegación. </f7-block-title>

<p>Adicionalmente, HICAPPS obtiene información de carácter no personal de las personas que visitan el Sitio a través de Google Analytics.

</p><p>Esta información no se refiere a datos específicos, identificados o identificables, si no a estadísticas generales para uso interno (por ejemplo: cantidad de visitas, ciudad de origen, formularios completados, formularios impresos, duración de la sesión, usuarios nuevos, usuarios / visitas repetidas, etc.). Con esta información, HICAPPS ajusta el Sitio y el Portal de acuerdo a las necesidades y requerimientos de las personas.

</p><p>Estos datos se recolectan a través de las “cookies” del navegador que pueda utilizar el usuario.

</p><p>Las cookies pueden mejorar la experiencia online de los visitantes, grabando preferencias mientras se “navega” por un sitio web en particular. De este modo, se utiliza una cookie de sesión temporal para gestionar la sesión del usuario. Estas cookies temporales se cancelan cuando la persona deja de usar su "browser" (función de búsqueda en la red). Asimismo, el browser puede instalar otro tipo de cookies en el computador. En efecto, cuando la persona se conecta (log in), este tipo de cookie nos indica si el sitio web ya ha sido visitado anteriormente o si se trata de la primera vez del visitante en el Sitio Web. De esta forma, la cookie registra la sesión y muestra el Sitio Web según la personalización dispuesta por el usuario.
</p>
<f7-block-title>7. Derechos del Usuario. </f7-block-title>

<p>Toda persona que facilite sus datos a HICAPPS SpA tiene los siguientes derechos:</p>
<ul>
<li>	Cualquier persona tiene derecho a obtener confirmación sobre si estamos tratando datos personales que le conciernan. Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines para los que fueron recogidos.
</li><li>	En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. Si ha otorgado el consentimiento para alguna finalidad específica, tiene derecho a retirarlo en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada. En estos supuestos se dejarán de tratar los datos o, en su caso, se dejarán de hacer para esa finalidad en concreto, salvo por motivos legítimos imperiosos o el ejercicio o la defensa de posibles reclamaciones.
</li><li>	El Usuario podrá ejercitar en cualquier momento sus derechos de acceso, rectificación, cancelación y oposición al tratamiento de sus Datos Personales, mediante solicitud realizada a la siguiente dirección de email: info@coronachile.com
</li>
</ul>
<p>Para proteger su privacidad y seguridad, tomaremos medidas razonables para comprobar su identidad antes de darle acceso a sus propios datos o permitir que los corrija.
</p>
<f7-block-title>8. Exclusión de Responsabilidad.</f7-block-title>

<p>Habiendo aceptado los términos y condiciones de nuestra Política de Privacidad y Protección de Datos, el uso que usted realice de este sitio web es bajo su propio riesgo. HICAPPS no será responsable frente a cualquier daño o perjuicio que cualquier persona pueda sufrir a causa de una violación de la confidencialidad causada por el uso de este sitio web o de cualquier información transmitida por intermedio de este sitio, ni asume responsabilidad alguna por cualquier daño a su equipo o cualquier pérdida de información.
</p>
<f7-block-title>9. Cambios a la Política de Privacidad y Protección de Datos. </f7-block-title>

<p>HICAPPS puede cambiar su Política de Privacidad y Protección de Datos en cualquier momento conforme la legislación vigente. Cualquier cambio en esta Política de Privacidad y Protección de Datos será publicado en esta página y haremos todo lo posible para notificarle a usted de cualquier cambio significativo.
</p><p>
El usuario deberá acceder periódicamente a esta Política de Privacidad y Protección de Datos para conocer en todo momento la última versión publicada de la misma.
</p>
<f7-block-title>10. Jurisdicción y Ley Aplicable.</f7-block-title>

<p>Todo lo relativo a la interpretación y cumplimiento de la presente Política de Privacidad y Protección de Datos de Derecho-Chile estará regido exclusivamente por las leyes de la República de Chile. Cualquier controversia será sometida a los Tribunales Ordinarios de Chile.
</p>
<f7-block-title>11. Contacto </f7-block-title>

<p>Si tuviera alguna duda o inquietud acerca de nuestra Política de Privacidad o de la recolección de datos, por favor envíenos un mensaje de correo electrónico a info@coronachile.com
</p>
<f7-block-title>12.  Declaración final.
</f7-block-title>
<p>El envío de Datos Personales, mediante los formularios o por el uso de otros servicios ofrecidos por HICAPPS supone el consentimiento expreso al tratamiento de los Datos Personales por HICAPPS en los términos descritos en la presente Política de Privacidad y Protección de Datos.
</p><p>
Se hace presente además, que por esta Política de Privacidad y Protección de Datos, HICAPPS no está suscribiendo contrato ni compromiso alguno que la obligue con sus Usuarios, si no que solo tiene una finalidad informativa.
</p>

   </f7-block>
</template>

<script>

export default {
    name: 'politica',
	
    data(){
        return {
         
        }
	},
	
 

	
   
    	
    methods:{
 

  
	

	},
}
</script>
<style>

</style>