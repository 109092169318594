<template>
<f7-app :params="f7params" >


  <!-- Your main view, should have "view-main" class -->
  <f7-view main class="safe-areas" url="/"></f7-view>


 

  <f7-login-screen id="my-login-screen" :opened="loginAbierto">
    <f7-view>
      <f7-page login-screen>
        <f7-login-screen-title class="no-margin-bottom">Corona Chile</f7-login-screen-title>
        <f7-block class="versionnum no-margin-top text-align-center margin-bottom-half">V. {{$store.state.appVersion}}</f7-block>
        <f7-block class="masgran no-margin-top">CoronaChile ha ahorrado tiempo invaluable a clínicos, al optimizar el proceso de registros para vigilancia epidemiológica. <f7-link @click="peroComo=true">Cómo?</f7-link></f7-block>
      <f7-block strong  class="text-align-center">HICAPPS SpA se enorgullece del aporte logrado con coronachile.com durante el combate de la pandemia. El servicio ha sido dado de baja de forma definitiva con fecha 2 de febrero de 2022.</f7-block>
       <template v-if="false">
        <f7-block class="text-align-center">
          <f7-button @click="loginPaciente()" raised fill large>soy PACIENTE</f7-button>
        </f7-block>
        <f7-block class="text-align-center">- o -</f7-block>
         <f7-block class="text-align-center">
          <f7-button @click="loginProfSalud()" raised fill large>soy PROFESIONAL de la SALUD</f7-button>
        </f7-block>
       </template>
        <br/><br/>
        <f7-list>
          <f7-block-footer>
           <f7-link @click="abrirPolitica()">Política de Privacidad</f7-link> <br/>info@coronachile.com<br/><f7-link popover-open=".popover-disclaimer"><b>Corona Chile &copy; 2020<f7-icon material="info"></f7-icon></b> </f7-link> 
          </f7-block-footer>
        </f7-list>


        <f7-popover class="popover-disclaimer">
          <f7-block class="smalfont">
Coronachile.com es una webAPP segura que disminuye los tiempos de registro para su médico tratante, agilizando la atención de pacientes, beneficiándolo a Usted y a los pacientes que esperan por un diagnóstico.
Al usar esta webAPP Ud. entrega su expreso consentimiento para que los datos personales suministrados a través de este formulario sean utilizados por su médico tratante y enviados por su prestador de salud a las autoridades sanitarias que correspondan. Coronachile.com dará estricto cumplimiento a lo dispuesto en la Ley Nº 19.628 sobre Protección de la Vida Privada en la custodia y protección de sus datos personales (Ver Política de Privacidad).
Coronachile.com es absolutamente independiente de los médicos tratantes, prestadores de salud y autoridades sanitarias y, en consecuencia, no le corresponde ninguna responsabilidad en las decisiones adoptadas por éstos.
          </f7-block>
  </f7-popover>
      </f7-page>
    </f7-view>
  </f7-login-screen>


  <f7-popup class="como-popup" tablet-fullscreen	 swipe-to-close :opened="peroComo" @popup:closed="peroComo = false">
      <f7-page>
        <f7-navbar title="Cómo?">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
         <img class="al100" :src="como">
        </f7-block>
      </f7-page>
    </f7-popup>


    <f7-popup class="grant-popup" :opened="consentModalOpened" @popup:closed="consentModalOpened = false">
      <f7-page class="grisback">
        <f7-navbar title="Política de Privacidad" :subtitle="soloconsulta?null:'y Aceptación de Uso'">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block class="politics" :class="{masalto:soloconsulta}">
        <politica></politica>
        </f7-block>
        <template v-if="!soloconsulta">
      <f7-block class="margin-half">Al continuar, declara que <b>ha leido</b> y <b>acepta</b> la Política de Privacidad y el tratamiento de datos personales.</f7-block>
      <f7-block><f7-button @click="aceptaPolitica()" fill raised color="green">Continuar</f7-button></f7-block>
      <f7-block class="no-margin-bottom"><f7-link color="red" popup-close><f7-icon material="chevron_left"></f7-icon> Cancelar</f7-link></f7-block>
        </template>
      </f7-page>
    </f7-popup>
</f7-app>
</template>
<script>

  import routes from '../js/routes.js';
import como from '../static/img/como.jpg';
import politica from '../components/politica.vue';

  export default {
    components:{politica},
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'CoronaChile', // App name
          theme: 'md', // Automatic theme detection


          // App routes
          routes: routes,
          dialog: {
            buttonCancel: 'Cancelar',
           }
        },

        // Login data
        loginAbierto: true,
        contrib: null,
        peroComo: false,
        como: como,
        //policita staff
         entregaConsent: false,
        whichpending:'patient',
        consentModalOpened: false,
        soloconsulta: false
      }
    },
    methods: {
      abrirPolitica(){
         this.soloconsulta=true;
         this.consentModalOpened=true;
      },
      aceptaPolitica(){
          this.entregaConsent=true;
           this.consentModalOpened=false;
          if(this.whichpending=='patient'){
            this.loginPaciente();
          }
          else{
            this.loginProfSalud();
          }

      },
      loginProfSalud() {
         if(!this.entregaConsent){
         this.soloconsulta=false;
         this.whichpending='profsalud';
         this.consentModalOpened=true;
       }
       else{
          let provider = new this.$firebase.auth.GoogleAuthProvider();
         this.$f7.dialog.preloader('Navegando a Auth...');
        this.$firebase.auth().signInWithRedirect(provider);
       }
      },
      loginPaciente(){
       if(!this.entregaConsent){
         this.soloconsulta=false;
         this.whichpending='patient';
         this.consentModalOpened=true;
       }
       else{
          this.$f7.dialog.preloader('Ingresando...');
          this.$firebase.auth().signInAnonymously().catch((error)=> {
            // Handle Errors here.
            let errorCode = error.code;
            let errorMessage = error.message;
            console.log(error);
            this.$f7.dialog.close();
            this.$f7.dialog.alert(errorMessage,errorCode);
          });
       }
      }
    },
    mounted() {
      this.$f7ready((f7) => {
        this.$f7.dialog.preloader('Cargando...');
        // Call F7 APIs here
        this.$firebase.auth().onAuthStateChanged((user)=> {
        if (user) {
          // User is signed in.
          console.log('USUARIO DETECTADO POR OBSERVABLE');
           console.log('welcome',user);
            let profile_userInfo={
              displayName: user.displayName,
              email: user.email,
               emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              phoneNumber: user.phoneNumber,
              uid: user.uid,
              isAnonymous: user.isAnonymous
            };
            this.$store.commit('setObjTo',{what:'profile_userInfo',to:profile_userInfo});
            if(user.isAnonymous){
              console.log(user.uid);
              //get su form
               let encodeme = this.$firebase.functions().httpsCallable('encodeme');
                encodeme({}).then((ladata)=> {
                  console.log('llego data',ladata);
                  let elform=ladata.data;
                  this.$store.commit('setObjTo',{what:'focusedForm',to:elform});
                  this.$f7.views.main.router.navigate('/paciente/');
                  this.$f7.dialog.close();
                  this.loginAbierto=false;
                }).catch((error)=>{
                  console.log('error en fx',error);
                  this.$f7.dialog.close();
                  this.$f7.dialog.alert(error.message,'Error');
                });
            }
            else{
              this.$firebase.database().ref('privx/'+user.uid).once('value').then(snpanshot=>{
                this.$store.commit('mergeObjTo',{what:'misprivx',to:snpanshot.val()||{}});
                this.$f7.views.main.router.navigate('/profsalud/');
                this.$f7.dialog.close();
                 this.loginAbierto=false;
              }).catch(error=>{
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message,error.code);
              });
              
            }
         
          // ...
        } else {
          // User is signed out.
          // ...
          this.$store.commit('setObjTo',{what:'profile_userInfo',to:{}});
          console.log('no user logged in');
          this.loginAbierto=true;
          this.$f7.dialog.close();
           this.entregaConsent=false;
            this.soloconsulta= false;

            // let contrib = this.$firebase.functions().httpsCallable('contrib');
            //     contrib({}).then((ladata)=> {
            //       console.log('llego data',ladata);
            //       let elform=ladata.data;
            //       // this.contrib=elform;
            //       let enhorasmin=this.$moment.duration(elform, 'minutes');
            //       let anos=enhorasmin.years();
            //       let meses=enhorasmin.months();
            //       let dias=enhorasmin.days();
            //       let horas=enhorasmin.hours();
            //       let minutos=enhorasmin.minutes();
                  
            //       let anostring=(anos>0?(anos+' año'+(anos==1?' ':'s ')):'');
            //       let messtring=(meses>0?(meses+' mes'+(meses==1?' ':'s ')):'');
            //       let diastring=(dias>0?(dias+' dia'+(dias==1?' ':'s ')):'');
            //       let horastring=(horas>0?(horas+' hora'+(horas==1?' ':'s ')):'');
            //       let minutostring=((minutos+' minuto'+(minutos==1?' ':'s ')));
            //       let lastring =(anostring+ messtring+diastring+horastring+' y '+minutostring);
            //       this.contrib=lastring;
            //     }).catch((error)=>{
            //       console.log('error en fx',error);
            //     });
        }
         
      });
      });
    }
  }
</script>
<style>
.versionnum{
  font-size: 13px;
    color: #b76115;
    font-weight: bolder;
}
.login-screen .page{
background: url('../static/img/coronaback.jpg');
background-repeat:no-repeat;
-webkit-background-size:cover;
-moz-background-size:cover;
-o-background-size:cover;
background-size:cover;
background-position:center;
}
.login-screen-content{
  background: unset;
}

.smart-select-page .page-content .list{
  margin-top:0px!important;
}
.smalfont{
  font-size: 11px!important;
}
.masgran{
  font-size:15px;
}
.grand{
  font-size:20px;
  font-weight: bold;
}
.al100{
  width:100%
}
.politics{
    margin:10px;
    border: 1px solid purple;
    height: 50%;
    padding: 0px;
    overflow: scroll;
    font-size: 11px;
    background: #FFF;
}
.nowhitewrap.list .item-title{
  white-space: unset!important;
}
.politics.masalto{
  height: 80%;
}
</style>