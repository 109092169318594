<template>
  <f7-page class="grisback" name="formainstitution">
    <f7-navbar title="Detalle Institución" back-link="Back"></f7-navbar>
    <f7-list>
        <f7-list-item divider title="Datos Institucionales para Formularios"></f7-list-item>
        <f7-list-item header="Institución" :title="focusedInstitucion.institucion || 'No definido'" :class="{nodef:!focusedInstitucion.institucion}" link="#" @click="editarOrig('institucion')">
          <f7-icon material="edit" slot="after"></f7-icon>
        </f7-list-item>
         <f7-list-item header="Unidad" :title="focusedInstitucion.unidad || 'No definido'" :class="{nodef:!focusedInstitucion.unidad}" link="#" @click="editarOrig('unidad')">
          <f7-icon material="edit" slot="after"></f7-icon>
        </f7-list-item>
    <f7-list-item header="Teléfono" :title="focusedInstitucion.fono || 'No definido'" :class="{nodef:!focusedInstitucion.fono}" link="#" @click="editarOrig('fono')">
          <f7-icon material="edit" slot="after"></f7-icon>
        </f7-list-item>

        <f7-list-item header="Fax" :title="focusedInstitucion.fax || 'No definido'" :class="{nodef:!focusedInstitucion.fax}" link="#" @click="editarOrig('fax')">
          <f7-icon material="edit" slot="after"></f7-icon>
        </f7-list-item>
              <f7-list-item header="Dirección" :title="focusedInstitucion.direccion || 'No definido'" :class="{nodef:!focusedInstitucion.direccion}" link="#" @click="editarOrig('direccion')">
          <f7-icon material="edit" slot="after"></f7-icon>
        </f7-list-item>
          <f7-list-item :title="focusedInstitucion.comuna || 'No definido'" header="Comuna"  :class="{nodef:!focusedInstitucion.comuna}" :footer="focusedInstitucion.comuna?(focusedInstitucion.provincia +' | '+ focusedInstitucion.region):'Indique comuna'" smart-select :smart-select-params="{ searchbar: true, searchbarPlaceholder: 'Búsque comuna', closeOnSelect: true}">
          <select @change="eligiendoComuna($event.target.value)" name="comuna" >
            <template v-for="region in comunas">
               <optgroup v-for="provincia in region.provincias" :key="region.region_number+provincia.name" :label="region.region+': '+provincia.name">
              <option v-for="comuna in provincia.comunas" :selected="selectedComuna==(comuna.name+'|'+provincia.name+'|'+region.region)" :key="comuna.code" :value="comuna.name+'|'+provincia.name+'|'+region.region">{{comuna.name}}</option>
              </optgroup>
            </template>
          </select>
        </f7-list-item>

      </f7-list>

  </f7-page>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import {formasmixin} from '../components/formasmixin';
import {comunashelper} from '../components/comunashelper';


export default {
     mixins: [formasmixin,comunashelper],
  data(){
    return{
    }
  },
    computed:{
		 ...mapState(['focusedInstitucion','profile_userInfo']),
		  selectedComuna(){
       return this.focusedInstitucion.comuna?(this.focusedInstitucion.comuna+'|'+this.focusedInstitucion.provincia+'|'+this.focusedInstitucion.region):'a|b|c';
     }

  },
  mounted(){
    console.log(this.focusedInstitucion,'focusedInstitucion');
  },
  methods:{
      editarOrig(cualprop){
    this.$f7.dialog.prompt('Indique '+cualprop,  (nombre)=> {
      let fixedNombre=nombre.trim();
       if(fixedNombre==""){
        return this.$f7.dialog.alert('Debes indicar '+cualprop);
      }
      this.$f7.dialog.preloader('Guardando');
        this.$firebase.database().ref('privx/'+this.profile_userInfo.uid+'/inst/'+this.focusedInstitucion.id+'/'+cualprop).set(fixedNombre).then(()=> {
          // Update successful.
          this.$store.commit('setPropToFocusedInsti',{what:cualprop,to:fixedNombre})
          this.$f7.dialog.close();
        }).catch((error)=> {
          // An error happened.
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message);
        });
      },
    ()=>{}
      ,
        (this.focusedInstitucion[cualprop]||null)

      );
  },
     eligiendoComuna(comuna){
      console.log('eligiendo comuna',comuna);
      let linfo=comuna.split('|');
      console.log(linfo);
      this.$f7.dialog.preloader('Guardando...');
      this.$firebase.database().ref('privx/'+this.profile_userInfo.uid+'/inst/'+this.focusedInstitucion.id).update({comuna: linfo[0] , provincia: linfo[1] , region: linfo[2] }).then(()=>{
          console.log('Info geografica guardada');
           this.$store.commit('setPropToFocusedInsti',{what:'comuna',to:linfo[0]});
            this.$store.commit('setPropToFocusedInsti',{what:'provincia',to:linfo[1]});
             this.$store.commit('setPropToFocusedInsti',{what:'region',to:linfo[2]});
           this.$f7.dialog.close();
      }).catch(error=>{
          this.$f7.dialog.close();
          this.$f7.dialog.alert(error.message,error.code);
      });
    },
  }
}
</script>
<style>

</style>